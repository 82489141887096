export function filterKeys(obj: any, keys: Array<string>): any {
  const n: any = {};
  for (const k of keys) {
    n[k] = obj[k] ?? null;
  }
  return n;
}

export function removeKeys(obj: any, keys: Array<string>): any {
  const n: any = {};
  for (const k of Object.keys(obj)) {
    if (!keys.includes(k)) {
      n[k] = obj[k] ?? null;
    }
  }
  return n;
}

export function clone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}
