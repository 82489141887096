import gql from "graphql-tag";
import { client } from "@/api/apollo";
import {
  CrudService,
  PageConsult,
  PageConsultInput,
  ServiceConsulta,
} from "./Services";
import { clone, filterKeys } from "@/utils/object";
import { User } from "./UserService";

let instance: PontoApoioAdminService;

export interface PontoApoio {
  id: number;
  nome?: String;
  apelido?: String;
  rgInsc?: String;
  createdAt?: Date;
  updatedAt?: Date;
  userId?: number;
  user?: User;
  indicadorId?: number;
  indicador?: User;
  tipo?: string;
  documento?: string;
  email?: string;
  detalhes?: string;
  responsaveis?: any[];
  telefone?: string;
  whatsapp?: string;
  cep?: string;
  logradouro?: string;
  enderecoNumero?: string;
  complemento?: string;
  referencia?: string;
  bairro?: string;
  cidade?: string;
  uf?: string;
  regiaoAtuacao?: string;
  status?: string;
  contratoAceito?: boolean;
  regimeTributario?: string;
  observacoes?: string;
}

export const initialPontoApoio: Readonly<PontoApoio> = Object.freeze({
  id: null!,
  nome: null!,
  apelido: null!,
  rgInsc: null!,
  userId: null!,
  user: null!,
  indicadorId: null!,
  indicador: null!,
  tipo: null!,
  documento: null!,
  email: null!,
  detalhes: null!,
  responsaveis: [],
  telefone: null!,
  whatsapp: null!,
  cep: null!,
  logradouro: null!,
  enderecoNumero: null!,
  complemento: null!,
  referencia: null!,
  bairro: null!,
  cidade: null!,
  uf: null!,
  regiaoAtuacao: null!,
  status: null!,
  contratoAceito: false,
  regimeTributario: null!,
  observacoes: null!,
});

export function getInstance(): PontoApoioAdminService {
  if (!instance) {
    instance = new PontoApoioAdminService();
  }
  return instance;
}

export interface PontoApoioPageConsultInput extends PageConsultInput {
}

export default class PontoApoioAdminService implements CrudService<PontoApoio> {
  async consultar(
    variables: PontoApoioPageConsultInput,
  ): Promise<PageConsult<PontoApoio>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            r: pontosApoioAdmin(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                nome
                apelido
                email
                telefone
                whatsapp
                status
                documento
                cidade
                uf
                user {
                  id
                  code
                  name
                  avatar
                }
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  private prepareData(input: PontoApoio) {
    const data = filterKeys(clone(input), [
      "nome",
      "apelido",
      "rgInsc",
      "userId",
      "indicadorId",
      "tipo",
      "documento",
      "email",
      "detalhes",
      "responsaveis",
      "telefone",
      "whatsapp",
      "cep",
      "logradouro",
      "enderecoNumero",
      "complemento",
      "referencia",
      "bairro",
      "cidade",
      "uf",
      "regiaoAtuacao",
      "contratoAceito",
      "regimeTributario",
      "observacoes",
    ]);
    input.user && (data.userId = input.user.id);
    input.indicador && (data.indicadorId = input.indicador.id);
    data.responsaveis = JSON.stringify(input.responsaveis);
    return data;
  }

  async cadastrar(input: PontoApoio): Promise<number> {
    const result = await client
      .mutate({
        variables: {
          input: this.prepareData(input),
        },
        mutation: gql`
          mutation($input: PontoApoioInput!) {
            r: createPontoApoio(input: $input)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async atualizar(id: number, data: PontoApoio) {
    await client
      .mutate({
        variables: {
          id,
          input: this.prepareData(data),
        },
        mutation: gql`
          mutation($id: ID!, $input: PontoApoioInput!) {
            r: updatePontoApoio(id: $id input: $input)
          }
        `,
      });
  }

  async excluir(id: number) {
    await client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation($id: ID!) {
            r: deletePontoApoio(id: $id)
          }
        `,
      });
  }

  async getById(id: number): Promise<PontoApoio> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            r: pontoApoio(id: $id) {
              id
              user {
                id
                code
                name
                pessoa {
                  documento
                }
              }
              indicador {
                id
                code
                name
                pessoa {
                  documento
                }
              }
              nome
              apelido
              rgInsc
              tipo
              documento
              email
              detalhes
              responsaveis
              telefone
              whatsapp
              cep
              logradouro
              enderecoNumero
              complemento
              referencia
              bairro
              cidade
              uf
              regiaoAtuacao
              contratoAceito
              regimeTributario
              observacoes
            }
          }
        `,
      });
    const { data: { r } } = result;
    r.responsaveis = !r.responsaveis ? [] : JSON.parse(r.responsaveis);
    return r;
  }

  async changeStatus(
    id: number,
    status: string,
  ): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          id,
          status,
        },
        mutation: gql`
          mutation($id: ID! $status: String!) {
            r: changePontoApoioStatus(id: $id status: $status)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }
}

export class PontoApoioService implements ServiceConsulta<PontoApoio> {
  async consultar(
    variables: PontoApoioPageConsultInput,
  ): Promise<PageConsult<PontoApoio>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            r: pontosApoio(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                nome
                apelido
                rgInsc
                status
                cidade
                logradouro
                uf
                telefone
                whatsapp
                email
                user {
                  id
                  code
                  name
                  avatar
                }
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async getById(id: number): Promise<PontoApoio> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            r: pontoApoio(id: $id) {
              id
              nome
              apelido
              documento
              detalhes
              telefone
              whatsapp
              cep
              logradouro
              enderecoNumero
              complemento
              referencia
              bairro
              cidade
              uf
              regiaoAtuacao
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }
}
