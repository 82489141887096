import { confirmLogout } from "@/services/auth";
import { store } from "@/store";

interface Menu {
  label: string;
  icon: string;
  to?: string;
  url?: string;
  target?: string;
  command?: Function;
  items?: Menu[];
  can?: Function;
}

export const menusUser = [
  {
    label: "Meus Dados",
    icon: "pi pi-fw pi-user",
    to: "/account",
  },
  {
    label: "Senha de Acesso",
    icon: "pi pi-fw pi-lock",
    to: "/change-password",
  },
  {
    label: "Senha Financeira",
    icon: "pi pi-fw pi-lock",
    to: "/change-password-financeira",
  },
  {
    label: "Suas Chaves PIX",
    icon: "pi pi-fw pi-key",
    to: "/pix-keys",
  },
  {
    label: "Mudar de Plano",
    icon: "pi pi-fw pi-arrow-circle-up",
    to: "/change-perfil",
  },
];

const menusAdmin = [
  {
    label: "Financeiro",
    icon: "pi pi-fw pi-dollar",
    items: [
      {
        label: "Saques",
        icon: "pi pi-fw pi-money-bill",
        to: "/admin/saques",
      },
    ],
  },
  {
    label: "Vendas",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "Pedidos",
        icon: "pi pi-fw pi-briefcase",
        to: "/admin/pedidos",
      },
    ],
  },
  {
    label: "Produtos",
    icon: "pi pi-fw pi-shopping-cart",
    items: [
      {
        label: "Meu Estoque",
        icon: "pi pi-fw pi-briefcase",
        to: "/admin/estoque",
      },
      {
        label: "Categorias",
        icon: "pi pi-fw pi-briefcase",
        to: "/admin/categorias",
      },
      {
        label: "Itens",
        icon: "pi pi-fw pi-shopping-cart",
        to: "/admin/itens",
      },
      {
        label: "Unid. Medidas",
        icon: "pi pi-fw pi-bars",
        to: "/admin/unidade-medidas",
      },
    ],
  },
  {
    label: "Logística",
    icon: "pi pi-fw pi-shopping-cart",
    items: [
      {
        label: "Embalagens",
        icon: "pi pi-fw pi-briefcase",
        to: "/admin/embalagens",
      },
    ],
  },
  {
    label: "Pontos de Apoio",
    icon: "pi pi-fw pi-map-marker",
    to: "/admin/pontos-apoio",
  },
  {
    label: "Relatórios",
    icon: "pi pi-fw pi-external-link",
    command() {
      window.open(
        `${process.env.VUE_APP_BASE_URL}/reports?t=${store.state.accessToken}`,
      );
    },
    target: "_blank",
  },
];

const menusPa = [
  {
    label: "Meu Estoque",
    icon: "pi pi-fw pi-briefcase",
    to: "/pa/estoque",
  },
  {
    label: "Pedidos",
    icon: "pi pi-fw pi-briefcase",
    to: "/pa/pedidos",
  },
  // {
  //   label: "Ativar Afiliado",
  //   icon: "pi pi-fw pi-user",
  //   to: "/pa/ativar-user",
  // },
  // {
  //   label: "Registrar Vendas",
  //   icon: "pi pi-fw pi-book",
  //   to: "/pa/vendas",
  // },
];

const menus: Menu[] = [
  { label: "Início", icon: "pi pi-fw pi-home", to: "/dashboard" },
  {
    label: "Minha Conta",
    icon: "pi pi-fw pi-user",
    items: menusUser,
  },
  {
    label: "Equipe",
    icon: "pi pi-fw pi-id-card",
    to: "/equipe",
  },
  {
    label: "Loja",
    icon: "pi pi-fw pi-shopping-cart",
    items: [
      {
        label: "Comprar",
        icon: "pi pi-fw pi-plus-circle",
        to: "/loja",
      },
      {
        label: "Meus Pedidos",
        icon: "pi pi-fw pi-book",
        to: "/pedidos",
      },
      {
        label: "Pontos de Apoio",
        icon: "pi pi-fw pi-map-marker",
        to: "/pontos-apoio",
      },
    ],
  },
  {
    label: "Ponto de Apoio",
    icon: "pi pi-fw pi-map-marker",
    can() {
      return store.state.user?.temPontoApoio;
    },
    items: menusPa,
  },
  {
    label: "Financeiro",
    icon: "pi pi-fw pi-dollar",
    items: [
      {
        label: "Extrato Financeiro",
        icon: "pi pi-fw pi-list",
        to: "/extrato-financeiro",
      },
      {
        label: "Extrato de Pontos",
        icon: "pi pi-fw pi-list",
        to: "/extrato-pontos",
      },
      {
        label: "Saques",
        icon: "pi pi-fw pi-money-bill",
        to: "/saques",
      },
    ],
  },
  {
    label: "Materiais",
    icon: "pi pi-fw pi-external-link",
    url:
      "https://drive.google.com/drive/folders/1_ln94NpM0wUzJZvRdaM05vhcAssbBDwQ?usp=sharing",
    target: "_blank",
  },
  {
    label: "Suporte",
    icon: "pi pi-fw pi-comments",
    url: "#",
  },
  {
    label: "Administração",
    icon: "pi pi-fw pi-user",
    can() {
      return store.state.user?.admin;
    },
    items: menusAdmin,
  },
  {
    label: "Sair",
    icon: "pi pi-fw pi-sign-out",
    command() {
      confirmLogout();
    },
  },
];

export default menus;
