<template>
  <div class="layout-profile">
    <div style="margin-bottom: 10px">
      <Avatar />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ $store.state.user?.name }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" @click="account">
            <i class="pi pi-fw pi-user"></i><span>Minha Conta</span>
          </button>
        </li>
        <li>
          <button class="p-link">
            <i class="pi pi-fw pi-bell"></i>
            <span>Notificações</span>
            <span
              v-if="$store.state.user?.unreadNotifications"
              class="menuitem-badge"
            >
              {{ $store.state.user?.unreadNotifications }}
            </span>
          </button>
        </li>
        <li>
          <button class="p-link" @click="logout">
            <i class="pi pi-fw pi-power-off"></i><span>Sair</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { confirmLogout } from "@/services/auth";
import { defineComponent } from "vue";
import Avatar from "../Avatar.vue";

export default defineComponent({
  emits: ["menuitem-click"],
  components: { Avatar },
  data() {
    return {
      expanded: false,
    };
  },
  setup(_, { emit }) {
    const router = useRouter();
    return {
      logout() {
        confirmLogout();
      },
      account(event: Event) {
        emit("menuitem-click", {
          originalEvent: event,
          item: {},
        });
        router.push({ name: "account" });
      },
    };
  },
  methods: {
    onClick(event: Event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
  },
});
</script>
