
import { defineComponent, PropType, reactive, ref, watch, computed } from "vue";
import {
  getInstance,
  Item,
  baseUrlImagens,
  calcValorAfiliado,
  calcPontos,
} from "@/services/ItemService";
import Carousel from "primevue/carousel";

export function useShowItem() {
  const data = reactive({
    id: null,
    title: "",
    visible: false,
    show(id: number, title = "Detalhes do Produto") {
      // @ts-ignore
      this.id = id;
      this.title = title;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-item-view",
  components: { Carousel },
  emits: ["update:visible"],
  props: {
    id: [Number, String] as PropType<number>,
    title: {
      type: String,
      default: "Detalhes do Produto",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<Item>();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = await getInstance().getById(id);
        data.value = result;
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    const valorAfiliado = computed(() => {
      return data.value ? calcValorAfiliado(data.value) : NaN;
    });
    const pontos = computed(() => {
      return data.value ? calcPontos(data.value, valorAfiliado.value) : 0;
    });

    return {
      valorAfiliado,
      pontos,
      data,
      loading,

      close,

      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },

      getUrlImagem(url: string) {
        return `${baseUrlImagens}/${url}`;
      },
    };
  },
});
