
import { defineComponent, ref, onMounted, reactive } from "vue";
import { recuperarSenha } from "@/services/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const msg = reactive({
      key: 0,
      type: "warn",
      text: "",
    });
    const email = ref<string>("");
    const loading = ref<boolean>(false);
    const router = useRouter();
    const elEmail = ref();

    async function enviar() {
      if (loading.value) {
        return;
      }
      loading.value = true;
      try {
        await recuperarSenha(email.value);
        (msg.type = "success"), msg.key++;
        msg.text = "Enviamos seu link de redefinição de senha por e-mail!";
      } catch (e) {
        (msg.type = "warn"), msg.key++;
        msg.text =
          "Não foi possível gerar o link de recuperação. Verifique se o e-mail está correto";
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      elEmail.value?.$el.focus();
    });

    return {
      email,
      enviar,
      msg,
      loading,
      elEmail,
    };
  },
});
