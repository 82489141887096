
import { computed, defineComponent, PropType, reactive, ref, watch } from "vue";
import { User, getLabelGraduacao } from "@/services/UserService";

export default defineComponent({
  name: "x-user-label-graduacao",
  props: {
    user: Object as PropType<User>,
  },
  setup(props, { emit }) {
    const info = computed(() => {
      return props.user ? getLabelGraduacao(props.user) : null;
    });

    return {
      info,
    };
  },
});
