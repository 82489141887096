<template>
  <p-dialog
    :header="title"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <div class="p-text-center" v-if="loading">
      <p-progress-spinner style="width: 50px; height: 50px" />
    </div>
    <div v-else-if="data">
      <div class="info">
        <label> Identificação: </label>
        <span>{{ data.apelido }}</span>
      </div>
      <div class="info">
        <label>Telefone:</label>
        <span>
          <a :href="`tel:${data.telefone}`">{{ data.telefone }}</a>
        </span>
      </div>
      <div class="info" v-if="data.whatsapp">
        <label>Whatsapp:</label>
        <span>
          <a
            href="#"
            @click.prevent="data?.whatsapp && openWhatsapp(data.whatsapp)"
            >{{ data.whatsapp }}</a
          >
        </span>
      </div>
      <div class="info">
        <label>Cidade/UF:</label>
        <span>{{ data.cidade }} - {{ data.uf }}</span>
      </div>
      <div class="info">
        <label>Endereço:</label>
        <span
          >{{ data.logradouro }} - {{ data.enderecoNumero }} -
          {{ data.bairro }}</span
        >
      </div>
      <div class="info" v-if="data.complemento">
        <label>Complemento:</label>
        <span>{{ data.complemento }}</span>
      </div>

      <div class="info" v-if="data.referencia" style="margin-top: 1em">
        <label>Referência:</label>
        <span>{{ data.referencia }}</span>
      </div>

      <div class="info" style="margin-top: 1em">
        <label>Detalhes:</label>
        <span style="color: blue">{{ data.detalhes }}</span>
      </div>

      <div class="info" style="margin-top: 1em">
        <label>Região de Atuação:</label>
        <span>{{ data.regiaoAtuacao }}</span>
      </div>
    </div>

    <div class="p-d-flex p-flex-row-reverse p-jc-start p-mt-5">
      <p-button
        label="Fechar"
        class="p-button-secondary p-mr-2"
        @click="close"
      />
    </div>
  </p-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import { PontoApoio, PontoApoioService } from "@/services/PontoApoioService";
import { openWhatsapp } from "@/utils/whats";

export function useShowPontoApoio() {
  const data = reactive({
    id: null,
    title: "",
    visible: false,
    show(id: number, title = "Ponto de Apoio") {
      // @ts-ignore
      this.id = id;
      this.title = title;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-ponto-apoio-view",
  emits: ["update:visible"],
  props: {
    id: [Number, String] as PropType<number>,
    title: {
      type: String,
      default: "Ponto de Apoio",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<PontoApoio>();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = await new PontoApoioService().getById(id);
        data.value = result;
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    return {
      openWhatsapp,
      data,
      loading,
      close,
      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },
    };
  },
});
</script>

<style scoped>
.info {
  margin: 5px;
  display: flex;
}
.info label {
  display: inline-block;
  min-width: 120px;
  text-align: right;
  margin-right: 5px;
}
.info span {
  font-weight: bold;
}
</style>