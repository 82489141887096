
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "x-mensagem-ativacao",
  setup() {
    const store = useStore();
    const perfil = computed(() => {
      return store.state.novoPerfil || store.state.user?.perfil;
    });
    return {
      perfil,
    };
  },
});
