
import { useConsulta } from "@/composables/crud";
import { ServiceConsulta } from "@/services/Services";
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/runtime-core";

export default defineComponent({
  name: "x-foreign",
  emits: ["update:modelValue"],
  props: {
    modelValue: Object,
    field: {
      type: [String, Function],
      required: true,
    },
    service: {
      type: Object as PropType<ServiceConsulta<any>>,
      required: true,
    },
    sortField: String,
    extra: Object,
  },
  setup(props, { emit }) {
    const filter = ref("");
    const { data, search, consultar } = useConsulta(props.service);
    search.sortField = props.sortField || "name";
    search.sortOrder = "ASC";

    function mountField(v: any) {
      if (!v) {
        return "";
      }
      return typeof props.field === "string"
        ? v?.[props.field]
        : props.field(v);
    }

    function updateFilter() {
      filter.value = mountField(props.modelValue);
    }
    watch(() => props.modelValue, updateFilter);
    onMounted(updateFilter);

    return {
      data,
      filter,
      mountField,
      consultar(evt: any) {
        search.q = evt.query;
        consultar();
      },
      itemSelect(evt: any) {
        emit("update:modelValue", evt.value);
      },
      clear() {
        emit("update:modelValue", null);
      },
    };
  },
});
