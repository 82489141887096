import { User } from "@/services/UserService";

const storage = globalThis.localStorage;

export default {
  accessToken() {
    return storage.getItem("api:access_token") || "";
  },
  setAccessToken(value: string) {
    if (!value) {
      storage.removeItem("api:access_token");
    } else {
      storage.setItem("api:access_token", value);
    }
  },
  refreshToken() {
    return storage.getItem("api:refresh_token") || "";
  },
  setRefreshToken(value: string) {
    if (!value) {
      storage.removeItem("api:refresh_token");
    } else {
      storage.setItem("api:refresh_token", value);
    }
  },
  getUser(): User | undefined {
    const obj = storage.getItem("api:user");
    if (!obj) {
      return undefined;
    }
    return JSON.parse(obj) as User;
  },
  setUser(value: User) {
    if (!value) {
      storage.removeItem("api:user");
    } else {
      storage.setItem("api:user", JSON.stringify(value));
    }
  },
};
