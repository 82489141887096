
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import {
  getInstance,
  Item,
  baseUrlImagens,
  calcValorAfiliado,
  calcPontos,
} from "@/services/ItemService";
import { useShowItem } from "@/components/ItemView.vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "@/store";

export default defineComponent({
  name: "x-item-card",
  emits: ["add", "show", "remover", "changeQtd"],
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
    initialQtd: Number,
    showRemover: {
      type: Boolean,
      default: false,
    },
    showAdicionar: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    loadingTotal: {
      type: Boolean,
      default: false,
    },
    labelAdicionar: {
      type: String,
      default: "Adicionar",
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const itemView = useShowItem();
    const qtd = ref(props.initialQtd || 1);
    const disponivel = computed(
      () =>
        props.item.estoque &&
        props.item.estoque > 0 &&
        props.item.status !== "indisponível"
    );
    const statusDisponivel = computed(() => {
      return !disponivel.value
        ? "Indisponível"
        : `Disponível${
            store.state.user?.pontoApoioCompra ? `: ${props.item.estoque}` : ""
          }`;
    });

    const valorAfiliado = computed(() => {
      return calcValorAfiliado(props.item);
    });
    const pontos = computed(() => {
      return calcPontos(props.item, valorAfiliado.value);
    });

    const toast = useToast();
    watch(qtd, (q, oldQtd) => {
      let max = props.item.estoque || 0;
      if (max > 500) {
        max = 500;
      }
      if (q > max) {
        toast.add({
          severity: "warn",
          summary: "Não há mais unidades disponíveis neste momento",
          life: 2_000,
        });
        nextTick(() => {
          qtd.value = max || 1;
        });
        return;
      }
      q !== oldQtd && emit("changeQtd", q);
    });

    return {
      valorAfiliado,
      pontos,
      qtd,
      disponivel,
      statusDisponivel,
      itemView,
      getUrlImagem(item: Item) {
        return (
          getInstance().getUrlImagem(item) ||
          require("@/assets/shop-placeholder.png")
        );
      },
    };
  },
});
