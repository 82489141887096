
import { computed, onMounted, onUnmounted, ref } from "@vue/runtime-core";
import { InfoResult, loadInfo } from "@/services/DashboardService";
import { getUrlAvatar, getLabelPerfil } from "@/services/UserService";
import LinkCadastro from "./LinkCadastro.vue";
import { useShowUser } from "@/components/UserView.vue";
import EventBus, { EVT_LOGO_CLICKED } from "@/eventBus";
import { useShowUserGraduacao } from "@/components/UserGraduacaoView.vue";

export default {
  components: { LinkCadastro },
  setup() {
    const userView = useShowUser();
    const userGraduacaoView = useShowUserGraduacao();

    const info = ref<InfoResult>();
    async function load() {
      info.value = await loadInfo();
    }
    onMounted(load);

    async function reload() {
      info.value = undefined;
      info.value = await loadInfo();
    }
    onMounted(() => {
      EventBus.on(EVT_LOGO_CLICKED, reload);
    });
    onUnmounted(() => {
      EventBus.off(EVT_LOGO_CLICKED, reload);
    });

    const infoCiclo = computed(() => {
      const ciclo = info.value?.me?.ciclo;
      return ciclo
        ? `${`${ciclo.cicloMes}`.padStart(2, "0")}/${ciclo.cicloAno}`
        : "";
    });

    const equipePerfis = computed(() => {
      return (info.value?.dashboard.equipe?.perfis || []) as {
        perfil_id: number;
        nome: string;
        count: number;
      }[];
    });
    const equipeGraduacoes = computed(() => {
      return (info.value?.dashboard.equipe?.graduacoes || []) as {
        graduacao_id: number;
        nome: string;
        count: number;
      }[];
    });

    return {
      userView,
      userGraduacaoView,
      info,
      getUrlAvatar,
      getLabelPerfil,
      infoCiclo,
      equipePerfis,
      equipeGraduacoes,
      nextCiclo() {
        const n = new Date();
        let month = n.getMonth() + 1;
        let year = n.getFullYear();
        return `${month}`.padStart(2, "0") + `/${year}`;
      },
      liberaCicloAnterior() {
        const n = new Date();
        let month = n.getMonth() + 1;
        let year = n.getFullYear();
        return "08/" + `${month}`.padStart(2, "0") + `/${year}`;
      },
      getPinGraduacao() {
        const user = info.value?.me;
        if (!user) {
          return "";
        }
        if (user.graduacao) {
          return `/img/pin/${user.graduacao.nome
            ?.toLowerCase()
            .replaceAll(" ", "_")}.png`;
        }
        return `/img/pin/${user.perfil?.nome.toLowerCase()}.png`;
      },
      getPinQualificacao() {
        const user = info.value?.me;
        if (!user) {
          return "";
        }
        if (info.value?.me.ciclo?.graduacao?.nome) {
          return `/img/pin/${info.value.me.ciclo?.graduacao?.nome
            ?.toLowerCase()
            .replaceAll(" ", "_")}.png`;
        }
        return `/img/pin/${user.perfil?.nome.toLowerCase()}.png`;
      },
    };
  },
};
