<template>
  <div class="public-container">
    <div class="p-d-flex p-jc-center p-ai-center p-flex-column">
      <div class="p-text-center">
        <img alt="Logo" src="assets/images/logo1.png" style="height: 200px" />
      </div>
      <p-card>
        <template #content>
          <div class="p-mb-4">
            Esqueceu sua senha? Sem problemas. Basta nos informar seu endereço
            de e-mail e nós enviaremos um link de redefinição de senha.
          </div>
          <form method="POST" @submit.prevent="enviar">
            <div class="p-fluid">
              <div class="p-field">
                <label for="email">E-mail</label>
                <p-inputtext
                  id="email"
                  type="email"
                  v-model="email"
                  required
                  ref="elEmail"
                  autocomplete="username"
                />
              </div>
            </div>

            <div v-if="msg.text">
              <p-message :severity="msg.type" :key="msg.key">
                {{ msg.text }}
              </p-message>
            </div>

            <div
              class="p-d-flex p-flex-row-reverse p-col-12"
              style="justify-content: end"
            >
              <p-button
                :icon="loading ? 'pi pi-spin pi-spinner' : ''"
                label="ENVIAR LINK DE RECUPERAÇÃO"
                :disabled="loading"
                type="submit"
              />
              <p-button
                class="p-button-secondary p-mr-2"
                label="Cancelar"
                @click="$router.push({ name: 'login' })"
              />
            </div>
          </form>
        </template>
      </p-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import { recuperarSenha } from "@/services/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const msg = reactive({
      key: 0,
      type: "warn",
      text: "",
    });
    const email = ref<string>("");
    const loading = ref<boolean>(false);
    const router = useRouter();
    const elEmail = ref();

    async function enviar() {
      if (loading.value) {
        return;
      }
      loading.value = true;
      try {
        await recuperarSenha(email.value);
        (msg.type = "success"), msg.key++;
        msg.text = "Enviamos seu link de redefinição de senha por e-mail!";
      } catch (e) {
        (msg.type = "warn"), msg.key++;
        msg.text =
          "Não foi possível gerar o link de recuperação. Verifique se o e-mail está correto";
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      elEmail.value?.$el.focus();
    });

    return {
      email,
      enviar,
      msg,
      loading,
      elEmail,
    };
  },
});
</script>
