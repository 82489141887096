import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { store } from "@/store";
import { ActionTypes } from "@/store/actions";
import { Item } from "./ItemService";
import { CarrinhoTotais } from "@/store/types";

let instance: CarrinhoService;

export function getInstance(): CarrinhoService {
  if (!instance) {
    instance = new CarrinhoService();
  }
  return instance;
}

export interface ItemCarrinho {
  id?: number;
  itemId: number;
  qtd: number;
  item?: Item;
}

export default class CarrinhoService {
  async addItem(item: ItemCarrinho): Promise<number> {
    const result = await client
      .mutate({
        variables: {
          itens: [item],
        },
        mutation: gql`
          mutation($itens: [ShoppingCartItemInput!]!) {
            setShoppingCartItem(itens: $itens)
          }
        `,
      });
    const { data: { setShoppingCartItem } } = result;
    store.dispatch(ActionTypes.UPDATE_CARRINHO);
    return setShoppingCartItem[0];
  }

  async unsetItem(item: ItemCarrinho): Promise<void> {
    await client
      .mutate({
        variables: {
          id: item.id,
        },
        mutation: gql`
          mutation($id: ID!) {
            unsetShoppingCartItem(id: $id)
          }
        `,
      });
    store.dispatch(ActionTypes.UPDATE_CARRINHO);
  }

  async clear(): Promise<void> {
    await client
      .mutate({
        mutation: gql`
          mutation {
            clearShoppingCart
          }
        `,
      });
    store.dispatch(ActionTypes.UPDATE_CARRINHO);
  }

  async get(): Promise<{ itens: ItemCarrinho[]; totais: CarrinhoTotais }> {
    const result = await client
      .query({
        query: gql`
          query {
            me {
              totais: shoppingCart {
                total
                totalPontos
                qtdItens
              }
              itens: shoppingCartItens {
                id
                qtd
                itemId
                item {
                  id
                  nome
                  imagens
                  valor
                  status
                  estoque
                  fatorPontuacao
                  categoria {
                    nome
                    fatorPontuacao
                    descontoAfiliado
                    descontoNo1pedido
                    descontoAdicionalPa
                    exclusivo1pedido
                  }
                }
              }
            }
          }
        `,
      });
    const { data: { me } } = result;
    me.itens.forEach((i: any) => {
      // console.log("##", i.item.imagens);
      if (typeof i.item.imagens === "string") {
        i.item.imagens = JSON.parse(i.item.imagens);
      }
    });
    return me;
  }
}
