
import { useStore } from "@/store";
import { defineComponent, ref } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import useClipboard from "vue-clipboard3";
// @ts-ignore
import VueQrcode from "vue3-qrcode";

export default defineComponent({
  components: { VueQrcode },
  setup() {
    const showQrCode = ref(false);
    const store = useStore();
    const cadastroLink = location.href.replace(
      /#\/.*/,
      `#/cadastro/${store.state.user?.guid}`
    );

    const { toClipboard } = useClipboard();

    const toast = useToast();

    return {
      showQrCode,
      cadastroLink,
      copy() {
        toClipboard(cadastroLink);
        toast.add({
          severity: "success",
          summary: "Link copiado",
          life: 3000,
        });
      },
    };
  },
});
