import router from "@/router";
import { store } from "@/store";
import { MutationTypes } from "@/store/mutations";
import axios from "axios";
import UserService from "./UserService";
import { baseURL, http as restApi } from "@/api/rest";

const authUrl = `${baseURL}/oauth/token`;
const clientId = process.env.VUE_APP_CLIENT_ID;
const clientSecret = process.env.VUE_APP_CLIENT_SECRET;

const http = axios.create({ baseURL: authUrl, timeout: 10_000 });

export async function login(email: string, password: string) {
  const response = await http({
    method: "post",
    data: {
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: "password",
      username: email,
      password: password,
    },
  });

  store.commit(MutationTypes.SET_SESSION, response.data);

  const me = await new UserService().me();
  store.commit(MutationTypes.SET_USER, me);
}

export async function confirmLogout() {
  if (!globalThis.confirm("Tem certeza que deseja sair?")) {
    return;
  }
  await logout();
}

export async function logout() {
  try {
    await restApi(`${baseURL}/api/logout`);
  } catch (e) {}
  // try {
  //   await fetch(`${baseURL}/logout`, {
  //     credentials: "include",
  //   });
  // } catch (e) {}
  store.commit(MutationTypes.LOGOUT);
  // router.push({ name: "home" });

  // if using grant_type password
  router.push({ name: "login" });
}

export function authorize() {
  globalThis.location.href =
    `${baseURL}/oauth/authorize?client_id=${clientId}&response_type=token`;
}

export async function authorizeCallback(params: any) {
  if (!params.access_token) {
    router.push({ name: "401" });
    return;
  }

  store.commit(MutationTypes.SET_SESSION, {
    refresh_token: "",
    ...params,
  });
  const me = await new UserService().me();
  store.commit(MutationTypes.SET_USER, me);
  router.push("/");
}

export async function recuperarSenha(email: string): Promise<any> {
  const http = axios.create({
    baseURL: `${baseURL}/api/forgot-password`,
    timeout: 10_000,
  });
  return await http({
    method: "post",
    data: {
      email,
    },
  });
}
