<template>
  <div
    class="card p-p-3 p-text-center"
    style="background-color: #ff86a3; font-weight: bold"
  >
    Você ainda não validou o seu e-mail. <br />
    Para fazer isso agora
    <a @click.prevent="dialog = true" href="#">clique aqui</a>
  </div>

  <p-dialog
    header="Validação de E-mail"
    v-model:visible="dialog"
    modal
    style="width: 400px"
  >
    <div class="p-text-center">
      <p-button
        label="Clique aqui para enviar o código de validação"
        @click="enviar"
        :loading="sending"
      />
    </div>
    <div class="p-text-center p-mt-2" v-if="enviado" style="color: green">
      <span>O código foi enviado e tem validade de 60 minutos</span>
    </div>
    <div class="p-text-center p-mt-4">
      <span>Informe o código recebido no seu e-mail:</span>
      <div class="p-mt-2">
        <p-inputtext
          type="number"
          v-model="codigo"
          style="text-align: center"
        />
      </div>
    </div>
    <div class="p-d-flex p-flex-row-reverse p-jc-start p-mt-5">
      <p-button
        label="Validar"
        class="p-mr-2"
        @click="validar"
        :disabled="!codigo"
        :icon="checking ? 'pi pi-spin pi-spinner' : ''"
      />
      <p-button
        label="Cancelar"
        class="p-button-secondary p-mr-2"
        @click="dialog = false"
      />
    </div>
  </p-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { http } from "@/api/rest";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "x-verificar-email",
  setup() {
    const enviado = ref(false);
    const sending = ref(false);
    const checking = ref(false);
    const dialog = ref(false);
    const codigo = ref("");

    const toast = useToast();

    return {
      enviado,
      sending,
      checking,
      codigo,
      dialog,
      async enviar() {
        sending.value = true;
        try {
          await http.get("api/users/check-email");
          toast.add({
            severity: "success",
            summary: "Código enviado",
            life: 3000,
          });
          enviado.value = true;
        } finally {
          sending.value = false;
        }
      },
      async validar() {
        checking.value = true;
        try {
          await http.post("api/users/check-email", {
            code: codigo.value,
          });
          document.location.reload();
        } finally {
          checking.value = false;
        }
      },
    };
  },
});
</script>
