
import { defineComponent, ref, onMounted } from "vue";
import { login as doLogin } from "@/services/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Login",
  setup() {
    const email = ref<string>("");
    const password = ref<string>("");
    const msg = ref<string>("");
    const msgKey = ref<number>(0);
    const loading = ref<boolean>(false);
    const router = useRouter();
    const elEmail = ref();

    async function login() {
      if (loading.value) {
        return;
      }
      loading.value = true;
      try {
        await doLogin(email.value, password.value);
        router.push("/");
      } catch (e) {
        msgKey.value++;
        msg.value =
          "Não foi possível efetuar o login. Verifique se o usuário e senha estão corretos";
      } finally {
        loading.value = false;
      }
    }

    function forgotPassword() {
      console.log("doit");
    }

    onMounted(() => {
      elEmail.value?.$el.focus();
    });

    return {
      email,
      password,
      login,
      msg,
      msgKey,
      loading,
      forgotPassword,
      elEmail,
    };
  },
});
