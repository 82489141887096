import { defineComponent, h, reactive, resolveComponent, toRef } from "vue";

type MessageType = "info" | "warning" | "error";

const payload = reactive({
  visible: false,
  type: "error",
  content: "",
});

export function showMessage(type: MessageType, content: string) {
  payload.visible = true;
  payload.type = type;
  payload.content = content;
}

// @ts-ignore
globalThis.tt = showMessage;

export function useMessage() {
  return {
    payload,
  };
}

export const MessageComponent = defineComponent({
  name: "MessageComponent",
  setup() {
    const { payload } = useMessage();

    const visible = toRef(payload, "visible");

    return () => {
      return h(
        resolveComponent("Sidebar"),
        {
          visible: visible.value,
          "onUpdate:visible": (value: boolean) => visible.value = value,
          baseZIndex: 1000,
          position: "bottom",
        },
        () =>
          h(
            resolveComponent("p-message"),
            {
              severity: payload.type,
              closable: false,
            },
            () => payload.content,
          ),
      );
    };
  },
});
