 <template>
  <div v-if="!info" class="p-grid p-fluid dashboard">
    <p-progress-spinner style="width: 50px; height: 50px" />
  </div>
  <div v-else class="p-grid p-fluid dashboard">
    <div v-if="!info.me.emailVerifiedAt" class="p-col-12">
      <x-verificar-email />
    </div>

    <div v-if="!info.me.primeiroPedido" class="p-col-12">
      <div
        class="card p-p-3 p-text-center"
        style="background-color: #f9c851; font-weight: bold"
      >
        <template v-if="info.me.expRede">
          Você ainda não ativou a sua conta para receber bonificações. A sua
          reserva de bonificações expira em
          {{ info.me.expRede.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1") }}
          Para ativar sua conta agora
          <a
            href="#loja"
          >clique aqui</a>
        </template>
      </div>
    </div>
    <template v-else>
      <div v-if="!info.me.ativadoRede" class="p-col-12">
        <div
          class="card p-p-3 p-text-center"
          style="background-color: lightcoral; font-weight: bold"
        >
          <template v-if="info.me.expRede">
            A sua conta está INATIVA desde o dia
            {{
              info.me.expRede.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1")
            }}. A reserva e liberação dos ganhos e pontuações de equipe dependem
            da ativação mensal. Para ativar sua conta agora
            <a
              href="#loja"
            >clique aqui</a>
          </template>
        </div>
      </div>
      <template v-else>
        <div v-if="info.me.ativado" class="p-col-12">
          <div
            class="card p-p-3 p-text-center"
            style="background-color: rgb(81 249 133); font-weight: bold"
          >
            Sua conta está ativa para receber e liberar as bonificações de
            equipe do ciclo atual ({{
              info.me.expCiclo?.replace(/(\d{4})-(\d{2})-\d{2}/, "$2/$1")
            }}). Para fazer novos pedidos
            <a
              href="#loja"
            >clique aqui</a>
          </div>
        </div>
        <div v-else class="p-col-12">
          <div
            class="card p-p-3 p-text-center"
            style="background-color: #f9c851; font-weight: bold"
          >
            <template v-if="info.me.expCiclo">
              Você ainda não realizou sua ativação no ciclo atual
              {{ nextCiclo() }}. A liberação dos ganhos e pontuações de equipe
              dependem da ativação mensal. Para ativar sua conta agora
              <a
                href="#loja"
              >clique aqui</a>
            </template>
          </div>
        </div>
      </template>
    </template>

    <div class="p-col-12">
      <LinkCadastro />
    </div>

    <div class="p-col-12 p-lg-4" v-if="info.me.perfil">
      <div class="card summary p-d-flex" style="padding-bottom: 0">
        <div class="p-d-flex p-flex-column" style="flex: 1">
          <span class="title" style="text-align: center">Graduação</span>
          <img
            style="max-width: 150px; align-self: center"
            :src="getPinGraduacao()"
          />
        </div>
        <div class="p-d-flex p-flex-column" style="flex: 1">
          <span class="title" style="text-align: center">Qualificação</span>
          <img
            style="max-width: 150px; align-self: center"
            :src="getPinQualificacao()"
          />
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="count" style="background-color: darkgrey">
          {{
            info.dashboard.totalEquipe
          }}
        </span>
        <span class="title">Equipe</span>
        <span class="detail">Quantidade de cadastros</span>
        <!-- <div class="micro-info">
          <span>Ativos Ciclo </span>
          <span class="total">{{
            info.dashboard.equipe?.ativosCiclo || 0
          }}</span>
        </div>
        <div class="micro-info">
          <span>Ativos Rede </span>
          <span class="total">{{
            info.dashboard.equipe?.ativosRede || 0
          }}</span>
        </div>
        <div class="micro-info" v-for="(p, i) in equipePerfis" :key="i">
          <span>{{ p.nome || "Sem pacote" }} </span>
          <span class="total">{{ p.count || 0 }}</span>
        </div>-->
      </div>
    </div>

    <!-- <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">Graduados Equipe</span>
        <div class="micro-info" v-for="(p, i) in equipeGraduacoes" :key="i">
          <span>{{ p.nome || "Sem graduação" }} </span>
          <span class="total">{{ p.count || 0 }}</span>
        </div>
      </div>
    </div>-->

    <div class="p-col-12 p-lg-4" v-if="info.me.ciclo">
      <div class="card summary">
        <span class="title">Pontos Pessoais Ciclo</span>
        <span class="detail">{{ infoCiclo }}</span>
        <span class="count revenue">
          {{
            $formatNumber(info.me.ciclo?.pp || 0, 0)
          }}
        </span>
      </div>
    </div>

    <div class="p-col-12 p-lg-4" v-if="info.me.ciclo">
      <div class="card summary">
        <span class="title">Pontos Unilevel Ciclo</span>
        <span class="detail">{{ infoCiclo }}</span>
        <span class="count revenue">
          {{
            $formatNumber(info.me.ciclo?.pu || 0, 0)
          }}
        </span>
      </div>
    </div>

    <div class="p-col-12 p-lg-4" v-if="info.me.ciclo">
      <div class="card summary">
        <span class="title">Pontos Graduação Ciclo</span>
        <span class="detail">{{ infoCiclo }}</span>
        <span class="count revenue">
          {{
            $formatNumber(info.me.ciclo?.pgv || 0, 0)
          }}
        </span>
        <span
          class="ver"
          @click="$store.state.user?.id && userGraduacaoView.show($store.state.user?.id)"
        >ver detalhes</span>
      </div>
    </div>

    <div class="p-col-12 p-lg-4" v-if="info.me.ciclo">
      <div class="card summary">
        <span class="title">Pontos de Viagem</span>
        <span class="detail">Estes pontos são acumulativos</span>
        <span class="count revenue">
          {{
            $formatNumber(info.me.pv || 0, 0)
          }}
        </span>
      </div>
    </div>

    <!-- Bônus Reservado Ciclo -->
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">Bônus à Liberar Ciclo</span>
        <span class="detail">{{ infoCiclo }}</span>
        <span class="count" style="background-color: darkorange">
          {{
            $formatCurrency(parseFloat(info.dashboard.saldos.ciclo) || 0)
          }}
        </span>
      </div>
    </div>
    <div
      class="p-col-12 p-lg-4"
      v-if="info.dashboard.saldos?.cicloAnterior > 0"
    >
      <div class="card summary">
        <span class="title">Bônus Ciclo Anterior</span>
        <span class="detail">Libera em {{ liberaCicloAnterior() }}</span>
        <span class="count" style="background-color: darkorange">
          {{
            $formatCurrency(parseFloat(info.dashboard.saldos?.cicloAnterior))
          }}
        </span>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">Saldo Disponível</span>
        <span class="detail">Para compras ou saque</span>
        <span class="count visitors">
          {{
            $formatCurrency(parseFloat(info.dashboard.saldos.disponivel) || 0)
          }}
        </span>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">Bônus Total</span>
        <span class="detail">Ganhos de equipe até o momento</span>
        <span class="count" style="background-color: darkgrey">
          {{
            $formatCurrency(parseFloat(info.dashboard.saldos.total) || 0)
          }}
        </span>
      </div>
    </div>

    <p-divider />

    <div class="p-col-12 p-lg-8 avisos">
      <Panel header="Avisos" style="height: 100%">
        <div class="videoWrapper">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/d_9vxNNAP_c"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <!-- <ul class="avisos-list">
          <li>
            <span class="task-name">Sales Reports</span>
            <i class="pi pi-check" />
          </li>
          <li>
            <span class="task-name">Pay Invoices</span>
            <i class="pi pi-check" />
          </li>
        </ul>-->
      </Panel>
    </div>

    <div class="p-col-12 p-lg-4 contacts">
      <Panel header="Últimos Cadastros na Equipe">
        <ul>
          <li v-for="e in info.equipe.data" :key="e.id">
            <button
              class="p-link p-d-flex"
              style="overflow: hidden"
              @click="userView.show(e.id)"
            >
              <div class="p-d-flex p-ai-center">
                <x-avatar
                  class="p-mr-3"
                  style="width: 40px; height: 40px"
                  :user="e"
                />
              </div>
              <div style="flex: 1; overflow: hidden">
                <div style="width: 100%" class="p-d-flex">
                  <div style="flex: 1; overflow: hidden" class="p-mr-2">
                    <p-tag
                      rounded
                      severity="info"
                      :value="e.pessoa.apelido || e.name"
                      style="
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                      "
                    />
                  </div>
                  <x-user-label-graduacao :user="e" />
                </div>
                <div class="detalhes p-mt-1">
                  {{
                    e.createdAt ? new Date(e.createdAt).toLocaleString() : ""
                  }}
                  <span
                    class="p-d-inline-block p-mr-2"
                  >(N {{ e.nivel }})</span>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </Panel>
    </div>
  </div>

  <x-user-view v-model:visible="userView.visible" :id="userView.id" />
  <x-user-graduacao-view
    v-model:visible="userGraduacaoView.visible"
    :id="userGraduacaoView.id"
  />
</template>

<script lang="ts">
import { computed, onMounted, onUnmounted, ref } from "@vue/runtime-core";
import { InfoResult, loadInfo } from "@/services/DashboardService";
import { getUrlAvatar, getLabelPerfil } from "@/services/UserService";
import LinkCadastro from "./LinkCadastro.vue";
import { useShowUser } from "@/components/UserView.vue";
import EventBus, { EVT_LOGO_CLICKED } from "@/eventBus";
import { useShowUserGraduacao } from "@/components/UserGraduacaoView.vue";

export default {
  components: { LinkCadastro },
  setup() {
    const userView = useShowUser();
    const userGraduacaoView = useShowUserGraduacao();

    const info = ref<InfoResult>();
    async function load() {
      info.value = await loadInfo();
    }
    onMounted(load);

    async function reload() {
      info.value = undefined;
      info.value = await loadInfo();
    }
    onMounted(() => {
      EventBus.on(EVT_LOGO_CLICKED, reload);
    });
    onUnmounted(() => {
      EventBus.off(EVT_LOGO_CLICKED, reload);
    });

    const infoCiclo = computed(() => {
      const ciclo = info.value?.me?.ciclo;
      return ciclo
        ? `${`${ciclo.cicloMes}`.padStart(2, "0")}/${ciclo.cicloAno}`
        : "";
    });

    const equipePerfis = computed(() => {
      return (info.value?.dashboard.equipe?.perfis || []) as {
        perfil_id: number;
        nome: string;
        count: number;
      }[];
    });
    const equipeGraduacoes = computed(() => {
      return (info.value?.dashboard.equipe?.graduacoes || []) as {
        graduacao_id: number;
        nome: string;
        count: number;
      }[];
    });

    return {
      userView,
      userGraduacaoView,
      info,
      getUrlAvatar,
      getLabelPerfil,
      infoCiclo,
      equipePerfis,
      equipeGraduacoes,
      nextCiclo() {
        const n = new Date();
        let month = n.getMonth() + 1;
        let year = n.getFullYear();
        return `${month}`.padStart(2, "0") + `/${year}`;
      },
      liberaCicloAnterior() {
        const n = new Date();
        let month = n.getMonth() + 1;
        let year = n.getFullYear();
        return "08/" + `${month}`.padStart(2, "0") + `/${year}`;
      },
      getPinGraduacao() {
        const user = info.value?.me;
        if (!user) {
          return "";
        }
        if (user.graduacao) {
          return `/img/pin/${user.graduacao.nome
            ?.toLowerCase()
            .replaceAll(" ", "_")}.png`;
        }
        return `/img/pin/${user.perfil?.nome.toLowerCase()}.png`;
      },
      getPinQualificacao() {
        const user = info.value?.me;
        if (!user) {
          return "";
        }
        if (info.value?.me.ciclo?.graduacao?.nome) {
          return `/img/pin/${info.value.me.ciclo?.graduacao?.nome
            ?.toLowerCase()
            .replaceAll(" ", "_")}.png`;
        }
        return `/img/pin/${user.perfil?.nome.toLowerCase()}.png`;
      },
    };
  },
};
</script>

<style scoped>
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.micro-info {
}
</style>
