<template>
  <p-dialog
    :header="title"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <div class="p-text-center" v-if="loading">
      <p-progress-spinner style="width: 50px; height: 50px" />
    </div>
    <div v-else-if="data">
      <div class="p-mb-2">
        <div
          style="color: #777; font-size: 0.8em"
          class="p-mb-2"
        >graduação / qualificação</div>
        <p-tag
          v-if="labelGraduacao"
          severity="info"
          :value="labelGraduacao.label"
          :style="`cursor: pointer;background-color: ${labelGraduacao.bgColor};${labelGraduacao.color ? `color:${labelGraduacao.color}` : ''
          }`"
          class="p-mr-1"
          @click="data?.id && userGraduacaoView.show(data.id)"
        />
        <p-tag
          v-if="labelQualificacao"
          severity="info"
          :value="labelQualificacao.label"
          :style="`cursor: pointer;background-color: ${labelQualificacao.bgColor};${labelQualificacao.color ? `color:${labelQualificacao.color}` : ''
          }`"
          @click="data?.id && userGraduacaoView.show(data.id)"
        />
      </div>
      <div class="p-text-center">
        <x-avatar :user="data" />
      </div>
      <div class="info">
        <label>ID:</label>
        <span>{{ data.code }}</span>
      </div>
      <div class="info">
        <label>
          {{
            data.pessoa.tipo === "F" ? "Nome Completo" : "Razão Social"
          }}:
        </label>
        <span>{{ data.name }}</span>
      </div>
      <div class="info">
        <label>{{ data.pessoa.tipo === "F" ? "Apelido" : "Nome Fantasia" }}:</label>
        <span>{{ data.pessoa.apelido || "-" }}</span>
      </div>
      <div class="info">
        <label>E-mail:</label>
        <span>{{ data.email }}</span>
      </div>
      <div class="info">
        <label>Telefone:</label>
        <span>{{ data.pessoa.telefone }}</span>
      </div>
      <div class="info">
        <label>Cidade:</label>
        <span>{{ data.pessoa.cidade }} - {{ data.pessoa.uf }}</span>
      </div>

      <div class="info p-mt-5">
        <label>Link de indicação:</label>
        <div
          class="p-d-inline-flex p-mt-1"
          style="justify-content: flex-end; align-items: center"
        >
          <a
            style="
              white-space: nowrap;
              overflow: hidden;
              max-width: 200px;
              text-overflow: ellipsis;
            "
            class="p-mr-1"
            :href="cadastroLink"
            target="_blank"
          >{{ cadastroLink }}</a>
          <p-button
            @click="copy"
            icon="pi pi-copy"
            class="p-button-secondary p-mr-2"
            title="Copiar para área de transferência"
          />
          <p-button
            icon="mdi mdi-qrcode-scan"
            title="Qrcode"
            @click="showQrCode = !showQrCode"
          />
        </div>
      </div>

      <div class="p-col-12 p-text-center" v-if="showQrCode">
        <vue-qrcode :value="cadastroLink" />
      </div>
    </div>

    <div class="p-d-flex p-jc-start p-mt-5" v-if="data">
      <div style="flex: 1" class="p-d-flex p-ai-center">
        <p-tag
          severity="info"
          :value="`${data.ativadoRede ? 'ativo' : 'inativo'} rede`"
          :style="getStyleAtivo(data.ativadoRede)"
          class="p-mr-1"
        />
        <p-tag
          severity="info"
          :value="`${data.ativado ? 'ativo' : 'inativo'} ciclo`"
          :style="getStyleAtivo(data.ativado)"
        />
      </div>
      <p-button
        label="Fechar"
        class="p-button-secondary p-mr-2"
        @click="close"
      />
    </div>

    <x-user-graduacao-view
      v-model:visible="userGraduacaoView.visible"
      :id="userGraduacaoView.id"
    />
  </p-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref, watch } from "vue";
import {
  getService,
  User,
  getLabelGraduacao,
  getLabelQualificacao,
} from "@/services/UserService";
import LinkCadastro from "@/pages/dashboard/LinkCadastro.vue";
import { useToast } from "primevue/usetoast";
import useClipboard from "vue-clipboard3";
// @ts-ignore
import VueQrcode from "vue3-qrcode";
import { useShowUserGraduacao } from "@/components/UserGraduacaoView.vue";

export function useShowUser() {
  const data = reactive({
    id: null,
    title: "",
    visible: false,
    show(id: number, title = "Afiliado(a)") {
      // @ts-ignore
      this.id = id;
      this.title = title;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-user-view",
  emits: ["update:visible"],
  components: { LinkCadastro, VueQrcode },
  props: {
    id: [Number, String] as PropType<number>,
    title: {
      type: String,
      default: "Afiliado(a)",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<User>();
    const userGraduacaoView = useShowUserGraduacao();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = await getService().getById(id);
        data.value = result;
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    const cadastroLink = computed(() => {
      return location.href.replace(/#\/.*/, `#/cadastro/${data.value?.guid}`);
    });
    const { toClipboard } = useClipboard();
    const toast = useToast();
    const showQrCode = ref(false);

    const labelGraduacao = computed(() => {
      return data.value ? getLabelGraduacao(data.value) : null;
    });
    const labelQualificacao = computed(() => {
      return data.value ? getLabelQualificacao(data.value) : null;
    });

    return {
      showQrCode,
      cadastroLink,
      data,
      loading,
      labelGraduacao,
      labelQualificacao,
      userGraduacaoView,

      close,

      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },
      copy() {
        toClipboard(cadastroLink.value);
        toast.add({
          severity: "success",
          summary: "Link copiado",
          life: 3000,
        });
      },

      getStyleAtivo(ativo: boolean) {
        return ativo
          ? "background-color: #689f38"
          : "background-color: #e1e1e1; color: #777";
      },
    };
  },
});
</script>

<style scoped>
.info {
  margin: 5px;
}
.info label {
  display: inline-block;
  width: 120px;
  text-align: right;
  margin-right: 5px;
}
.info span {
  font-weight: bold;
}
</style>