<template>
  <div class="card p-d-flex p-flex-wrap">
    <div class="p-col-12 p-md-4 p-text-center p-text-md-left">
      Seu ID é <b>{{ $store.state.user?.code }}</b>
      <br />
      <b>Seu link de indicação:</b>
    </div>
    <div class="p-col-12 p-md-4" style="font-weight: bold">
      <a :href="cadastroLink" target="_blank">{{ cadastroLink }}</a>
    </div>
    <div class="p-col-12 p-md-4 p-text-right">
      <p-button
        @click="copy"
        icon="pi pi-copy"
        class="p-button-secondary p-mr-2"
        title="Copiar para área de transferência"
      />
      <p-button
        icon="mdi mdi-qrcode-scan"
        title="Qrcode"
        @click="showQrCode = !showQrCode"
      />
    </div>
    <div class="p-col-12 p-text-center" v-if="showQrCode">
      <vue-qrcode :value="cadastroLink" />
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { defineComponent, ref } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import useClipboard from "vue-clipboard3";
// @ts-ignore
import VueQrcode from "vue3-qrcode";

export default defineComponent({
  components: { VueQrcode },
  setup() {
    const showQrCode = ref(false);
    const store = useStore();
    const cadastroLink = location.href.replace(
      /#\/.*/,
      `#/cadastro/${store.state.user?.guid}`
    );

    const { toClipboard } = useClipboard();

    const toast = useToast();

    return {
      showQrCode,
      cadastroLink,
      copy() {
        toClipboard(cadastroLink);
        toast.add({
          severity: "success",
          summary: "Link copiado",
          life: 3000,
        });
      },
    };
  },
});
</script>