<template>
  <div
    v-if="$store.state.user?.temPontoApoio"
    class="card p-p-3 p-text-center"
    style="background-color: #ff86a3; font-weight: bold; margin: 0.2em auto"
  >
    <template v-if="$store.state.user?.pontoApoioCompra">
      Você está comprando para o seu usuário. Se deseja compara para o seu Ponto
      de Apoio <a href="#change-pa">clique aqui</a>
    </template>
    <template v-else>
      Você está comprado para o seu Ponto de Apoio. Se deseja comprar para o seu
      usuário? <a href="#change-pa">clique aqui</a>
    </template>
  </div>

  <div
    v-else
    class="card p-p-3 p-text-center"
    style="background-color: #ff86a3; font-weight: bold; margin: 0.2em auto"
  >
    <template v-if="$store.state.user?.pontoApoioCompra">
      Você está comprando no Ponto de Apoio
      <b>{{ $store.state.user?.pontoApoioCompra.apelido }}</b
      >. Para trocar <a href="#change-pa">clique aqui</a>
    </template>
    <template v-else>
      Deseja comprar de um Ponto de Apoio?
      <a href="#change-pa">clique aqui</a>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "x-ponto-apoio-compra",
});
</script>
