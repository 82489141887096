import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { clone, removeKeys } from "@/utils/object";

let instance: ConfiguracaoService;

export function getInstance(): ConfiguracaoService {
  if (!instance) {
    instance = new ConfiguracaoService();
  }
  return instance;
}

export interface Configuracao {
  ppPrimeiroPedido?: number;
  descontoPrimeiroPedido?: number;
  pgPrimeiroPedido?: number;
  valorMinimoPrimeiroPedido?: number;
  ppMensal?: number;
}

export default class ConfiguracaoService {
  async get(args: Array<keyof Configuracao>): Promise<Configuracao> {
    const result = await client
      .query({
        query: gql`{ configuracao { ${args.join(" ")} }}`,
      });
    const { data: { configuracao } } = result;
    return configuracao;
  }

  async set(input: Configuracao): Promise<void> {
    const data = removeKeys(clone(input), ["__typename"]);
    await client
      .mutate({
        variables: {
          input: data,
        },
        mutation: gql`
          mutation($input: Configuracao) {
            updateConfiguracao(input: $input)
          }
        `,
      });
  }
}
