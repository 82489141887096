import { isMobile } from "./browser";

export function openWhatsapp(number: string) {
  if (!number.startsWith("55")) {
    number = `55${number}`;
  }
  const tel = number.replace(/\D/g, "");
  const api = `https://${
    isMobile() ? "api" : "web"
  }.whatsapp.com/send?l=pt_BR&phone=${tel}`;
  window.open(api, "_blank");
}
