
import { defineComponent, PropType, reactive, ref, watch, computed } from "vue";
import {
  User,
  Graduacao,
  getPinByGraduacao,
} from "@/services/UserService";
import { http } from "@/api/rest";
import { useShowUser } from "@/components/UserView.vue";

interface Info {
  me: User
  qualificacaoAtual?: Graduacao
  qualificacaoProxima?: Graduacao
  cicloMes: number
  cicloAno: number
  pontosValidos: number
  detalhes: {
    pontos: number
    validos: number
    user: User
    descricao: string
  }[]
}

export function useShowUserGraduacao() {
  const data = reactive({
    id: null,
    visible: false,
    show(id: number) {
      // @ts-ignore
      this.id = id;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-user-graduacao-view",
  emits: ["update:visible"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<Info>();
    const userView = useShowUser();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = (await http.get(`api/user-graduacao-info?user=${id}`)).data;
        data.value = result;
        if (data.value?.detalhes?.[0]) {
          data.value.detalhes[0].descricao = "PONTOS PRÓPRIOS";

          const linhas = data.value.detalhes;

          data.value?.detalhes.push({
            user: null!,
            descricao: `${linhas.length - 1} linha(s)`,
            pontos: linhas.reduce((tot, r) => tot + r.pontos, 0),
            validos: linhas.reduce((tot, r) => tot + r.validos, 0)
          });
        }
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    const pinStart = computed(() => {
      return data.value
        ? getPinByGraduacao(data.value.me, data.value.qualificacaoAtual?.nome || "")
        : "";
    });
    const pinEnd = computed(() => {
      return data.value
        ? getPinByGraduacao(data.value.me, data.value.qualificacaoProxima?.nome || "")
        : "";
    });
    const progresso = computed(() => {
      if (data.value) {
        return data.value.pontosValidos / (data.value.qualificacaoProxima?.pg || 1) * 100;
      }
      return 0;
    });

    const pontosFaltantes = computed(() => {
      const validos = data.value?.pontosValidos || 0;
      const pg = data.value?.qualificacaoProxima?.pg || 0;
      return pg - validos;
    });

    return {
      data,
      loading,
      pinStart,
      pinEnd,
      pontosFaltantes,
      userView,
      progresso,

      close,

      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },
    };
  },
});
