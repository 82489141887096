
import { computed, defineComponent, PropType, reactive, ref, watch } from "vue";
import {
  getService,
  User,
  getLabelGraduacao,
  getLabelQualificacao,
} from "@/services/UserService";
import LinkCadastro from "@/pages/dashboard/LinkCadastro.vue";
import { useToast } from "primevue/usetoast";
import useClipboard from "vue-clipboard3";
// @ts-ignore
import VueQrcode from "vue3-qrcode";
import { useShowUserGraduacao } from "@/components/UserGraduacaoView.vue";

export function useShowUser() {
  const data = reactive({
    id: null,
    title: "",
    visible: false,
    show(id: number, title = "Afiliado(a)") {
      // @ts-ignore
      this.id = id;
      this.title = title;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-user-view",
  emits: ["update:visible"],
  components: { LinkCadastro, VueQrcode },
  props: {
    id: [Number, String] as PropType<number>,
    title: {
      type: String,
      default: "Afiliado(a)",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<User>();
    const userGraduacaoView = useShowUserGraduacao();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = await getService().getById(id);
        data.value = result;
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    const cadastroLink = computed(() => {
      return location.href.replace(/#\/.*/, `#/cadastro/${data.value?.guid}`);
    });
    const { toClipboard } = useClipboard();
    const toast = useToast();
    const showQrCode = ref(false);

    const labelGraduacao = computed(() => {
      return data.value ? getLabelGraduacao(data.value) : null;
    });
    const labelQualificacao = computed(() => {
      return data.value ? getLabelQualificacao(data.value) : null;
    });

    return {
      showQrCode,
      cadastroLink,
      data,
      loading,
      labelGraduacao,
      labelQualificacao,
      userGraduacaoView,

      close,

      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },
      copy() {
        toClipboard(cadastroLink.value);
        toast.add({
          severity: "success",
          summary: "Link copiado",
          life: 3000,
        });
      },

      getStyleAtivo(ativo: boolean) {
        return ativo
          ? "background-color: #689f38"
          : "background-color: #e1e1e1; color: #777";
      },
    };
  },
});
