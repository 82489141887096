<template>
  <div class="product-card">
    <img
      :src="getUrlImagem(item)"
      :alt="item.nome"
      @click.stop="$emit('show', item)"
    />
    <div class="product-card-detail">
      <div class="product-name">
        <a @click="$emit('show', item)" style="cursor: pointer">
          {{ item.nome }}
        </a>
      </div>
      <div class="p-mt-1">
        <i class="pi pi-tag product-category-icon"></i
        ><span class="product-category">{{ item.categoria.nome }}</span>
      </div>
      <div class="p-mt-1">
        <span class="product-price">
          <span style="font-size: 0.8rem">
            De
            <span style="text-decoration: line-through">{{
              $formatCurrency(item.valor || 0)
            }}</span>
            por
          </span>
          {{ $formatCurrency(valorAfiliado || 0) }}
          <span style="font-size: 0.8rem" v-if="!$store.getters.isCompraPa">
            ({{ $formatNumber(pontos) }} pts)
          </span>
        </span>
      </div>
      <div class="p-mt-1" v-if="showStatus">
        <span
          :class="
            'product-badge status-' + (disponivel ? 'instock' : 'outofstock')
          "
        >
          {{ statusDisponivel }}
        </span>
      </div>
    </div>
    <div class="product-list-action">
      <div v-if="!item.categoria.exclusivo1pedido">
        <p-button
          v-if="showRemover"
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-plain p-mr-3"
          @click="$emit('remover')"
        />
        <x-input-number
          v-if="disponivel || showRemover"
          v-model="qtd"
          mode="decimal"
          showButtons
          :min="1"
          buttonLayout="horizontal"
          decrementButtonClass="p-button-danger"
          incrementButtonClass="p-button-success"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          class="product-card-qtd"
          :disabled="loadingTotal"
        />
        <p-button
          v-if="showAdicionar && (disponivel || showRemover)"
          class="p-ml-2"
          icon="pi pi-shopping-cart"
          :label="labelAdicionar"
          @click="$emit('add', qtd)"
          :disabled="!disponivel"
        />
      </div>
      <div v-if="showTotal" class="p-text-right p-mt-1">
        <p-progress-spinner
          v-if="loadingTotal"
          style="width: 15px; height: 15px"
        />
        <span class="product-price">
          {{ $formatCurrency((valorAfiliado || 0) * qtd) }}
        </span>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import {
  getInstance,
  Item,
  baseUrlImagens,
  calcValorAfiliado,
  calcPontos,
} from "@/services/ItemService";
import { useShowItem } from "@/components/ItemView.vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "@/store";

export default defineComponent({
  name: "x-item-card",
  emits: ["add", "show", "remover", "changeQtd"],
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
    initialQtd: Number,
    showRemover: {
      type: Boolean,
      default: false,
    },
    showAdicionar: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    loadingTotal: {
      type: Boolean,
      default: false,
    },
    labelAdicionar: {
      type: String,
      default: "Adicionar",
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const itemView = useShowItem();
    const qtd = ref(props.initialQtd || 1);
    const disponivel = computed(
      () =>
        props.item.estoque &&
        props.item.estoque > 0 &&
        props.item.status !== "indisponível"
    );
    const statusDisponivel = computed(() => {
      return !disponivel.value
        ? "Indisponível"
        : `Disponível${
            store.state.user?.pontoApoioCompra ? `: ${props.item.estoque}` : ""
          }`;
    });

    const valorAfiliado = computed(() => {
      return calcValorAfiliado(props.item);
    });
    const pontos = computed(() => {
      return calcPontos(props.item, valorAfiliado.value);
    });

    const toast = useToast();
    watch(qtd, (q, oldQtd) => {
      let max = props.item.estoque || 0;
      if (max > 500) {
        max = 500;
      }
      if (q > max) {
        toast.add({
          severity: "warn",
          summary: "Não há mais unidades disponíveis neste momento",
          life: 2_000,
        });
        nextTick(() => {
          qtd.value = max || 1;
        });
        return;
      }
      q !== oldQtd && emit("changeQtd", q);
    });

    return {
      valorAfiliado,
      pontos,
      qtd,
      disponivel,
      statusDisponivel,
      itemView,
      getUrlImagem(item: Item) {
        return (
          getInstance().getUrlImagem(item) ||
          require("@/assets/shop-placeholder.png")
        );
      },
    };
  },
});
</script>

<style scoped lang="scss">
.product-name {
  font-size: 1.2rem;
  font-weight: 700;
}
.product-description {
  margin: 0 0 1rem 0;
}
.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}
.product-category {
  font-weight: 600;
  vertical-align: middle;
}
::v-deep(.product-card-qtd) {
  margin-bottom: 0.5rem;
  > input {
    width: 50px;
    text-align: center;
  }
}

.product-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  position: relative;
  img {
    width: 90px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1.5rem;
    cursor: pointer;
  }
  .product-card-detail {
    flex: 1 1 0;
    > div {
      padding: 0.1em;
    }
  }
  .product-price {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }
  .product-list-action {
    display: flex;
    flex-direction: column;
  }
  .p-button {
    margin-bottom: 0.5rem;
  }
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}

@media screen and (max-width: 576px) {
  .product-card {
    flex-direction: column;
    align-items: center;
    img {
      width: 75%;
      margin: 1rem 0;
      max-width: 150px;
    }
    .product-card-detail {
      text-align: center;
    }
    .product-price {
      align-self: center;
    }
    .product-list-action {
      display: flex;
      flex-direction: column;
    }
    .product-list-action {
      margin-top: 1rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

.p-inputnumber {
  width: unset !important;
}
</style>