import { Getter, GetterTree } from "vuex";
import { State } from "./types";

export interface GettersType<S> {
  isAuthenticated: Getter<S, boolean> | boolean;
}

export const getters: GetterTree<State, State> & GettersType<State> = {
  isAuthenticated(state) {
    return !!state.accessToken;
  },
  isCompraPa(state) {
    return state.user?.temPontoApoio && !state.user?.pontoApoioCompra;
  },
};
