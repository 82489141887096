<template>
  <div
    v-if="!$store.state.user?.primeiroPedido || $store.state.novoPerfil"
    class="card p-p-3 p-text-left p-col-12"
    style="background-color: #f9c851; font-weight: bold"
  >
    Para ativar o seu plano {{ perfil.nome }}, faça um pedido no valor mínimo de
    {{ $formatCurrency(perfil.valorMinimo1pedido || 0) }} ou
    {{ perfil.pp1pedido }} pontos. Caso queira, poderá
    adicionar mais produtos já com o desconto de afiliado aproveitando assim o
    mesmo frete.
    Materiais de apoio não são considerados na soma do valor mínimo.
  </div>

  <div
    v-else-if="
      !$store.state.user?.ativado &&
      $store.state.user?.expCiclo &&
      perfil.ppCiclo
    "
    class="card p-p-3 p-text-left p-col-12"
    style="background-color: #f9c851; font-weight: bold"
  >
    Para fazer a sua ativação mensal é necessário um pedido mínimo de
    {{ perfil.ppCiclo }} pontos. Materiais de apoio não são considerados na soma
    do valor mínimo.
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "x-mensagem-ativacao",
  setup() {
    const store = useStore();
    const perfil = computed(() => {
      return store.state.novoPerfil || store.state.user?.perfil;
    });
    return {
      perfil,
    };
  },
});
</script>