import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { PageConsult, PageConsultInput, ServiceConsulta } from "./Services";
import { clone, filterKeys } from "@/utils/object";
import { baseURL, http } from "@/api/rest";
import { Pessoa, pessoaInputKeys } from "./PessoaService";
import { PontoApoio } from "./PontoApoioService";
import { PerfilAfiliado } from "./PerfilAfiliadoService";

let instance: UserService;

export interface User {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  primeiroPedido?: Date;
  code: number;
  name: string;
  email: string;
  emailVerifiedAt?: string;
  guid: string;
  avatar?: string;
  ativado: boolean;
  expCiclo?: String;
  nivel?: number;
  admin?: boolean;
  pessoaId: number;
  pessoa: Pessoa;
  ativadoRede: boolean;
  expRede?: String;
  pv?: number;
  perfil?: PerfilAfiliado;
  perfilUpgrade?: PerfilAfiliado;
  temPwdFinanceira?: boolean;

  aceitaReceberInfo?: string;
  aceitaContrato?: string;

  unreadNotifications?: number;

  graduacao?: Graduacao;
  ciclo?: CicloPonto;

  temPontoApoio?: boolean;
  pontoApoioCompra?: PontoApoio;

  pgv?: number;
}

export interface Graduacao {
  id?: number;
  nome?: string;
  pg?: number;
  plr?: number;
  vme?: number;
}

interface CicloPonto {
  cicloMes?: number;
  cicloAno?: number;
  pp?: number;
  pu?: number;
  pg?: number;
  pv?: number;
  pgv?: number; // pontos de graduação válidos
  graduacao?: Graduacao;
}

export interface PixKey {
  chave: string;
  descricao: string;
  status?: string;
}

export const emptyUser: Readonly<User> = Object.freeze({
  id: null!,
  code: null!,
  name: "",
  email: "",
  guid: "",
  ativado: false,
  pessoaId: null!,
  pessoa: null!,
  ativadoRede: false,
});

export interface UserInput {
  id: number;
  name: string;
  email: string;
  pessoa: Pessoa;
  aceitaReceberInfo?: string;
  aceitaContrato?: string;
}
const userInputKeys: Array<keyof UserInput> = [
  "id",
  "name",
  "email",
  "pessoa",
  "aceitaReceberInfo",
];

export interface UserChangePassword {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export function getUrlAvatar(user: User) {
  return `${baseURL}/api/files/-/users-avatar/${user.avatar}`;
}

export function getService(): UserService {
  if (!instance) {
    instance = new UserService();
  }
  return instance;
}

const graduacoesCores: { [key: string]: string } = {
  "CONSULTOR": "#489fdd",
  "EXECUTIVO": "#f89320",
  "BRONZE": "#744e43",
  "OURO": "#c78d31",
  "DIAMANTE": "#5b74b6",
  "BLUE DIAMOND": "#023c9d",
  "RED DIAMOND": "#ca424e",
};

export function getLabelGraduacao(
  user: User,
): { label: string; bgColor: string; color?: string } {
  if (user.graduacao?.nome) {
    return {
      label: user.graduacao.nome,
      bgColor: graduacoesCores[user.graduacao.nome] || "",
    };
  }
  return getLabelPerfil(user);
}

export function getLabelQualificacao(
  user: User,
): { label: string; bgColor: string; color?: string } {
  if (user.ciclo?.graduacao?.nome) {
    return {
      label: user.ciclo?.graduacao?.nome,
      bgColor: graduacoesCores[user.ciclo?.graduacao?.nome] || "",
    };
  }
  return getLabelPerfil(user);
}

export function getLabelPerfil(
  user: User,
): { label: string; bgColor: string; color?: string } {
  if (user.perfil?.nome) {
    return {
      label: user.perfil.nome,
      bgColor: graduacoesCores[user.perfil.nome] || "",
    };
  }
  return {
    label: "Sem adesão",
    bgColor: "#e1e1e1",
    color: "#777",
  };
}

export function getPinByGraduacao(
  user: User,
  graduacaoNome: string,
) {
  if (graduacaoNome) {
    return `/img/pin/${
      graduacaoNome
        .toLowerCase()
        .replaceAll(" ", "_")
    }.png`;
  }
  return `/img/pin/${user?.perfil?.nome.toLowerCase()}.png`;
}

export default class UserService implements ServiceConsulta<User> {
  async consultar(params: PageConsultInput) {
    return await this.findEquipe(params);
  }

  async me(): Promise<User> {
    const result = await client
      .query({
        query: gql`
          query {
            me {
              id
              code
              name
              email
              avatar
              ativado
              expCiclo
              ativadoRede
              expRede
              primeiroPedido
              guid
              admin
              emailVerifiedAt
              temPwdFinanceira
              temPontoApoio
              pessoa {
                apelido
                telefone
                cep
                documento
              }
              unreadNotifications
              perfil {
                id
                nome
                pp1pedido
                desconto1pedido
                valorMinimo1pedido
                pg1pedido
                pgCiclo
                pu1pedido
                descontoRecompra
                participaGraduacao
                participaViagens
              }
              perfilUpgrade {
                id
                nome
                pp1pedido
                desconto1pedido
                valorMinimo1pedido
                pg1pedido
                pgCiclo
                pu1pedido
                descontoRecompra
                participaGraduacao
                participaViagens
              }
              pontoApoioCompra {
                id
                apelido
              }
            }
          }
        `,
      });
    const { data: { me } } = result;
    return me as User;
  }

  async meAccount(): Promise<User> {
    const result = await client
      .query({
        query: gql`
          query {
            me {
              id
              code
              name
              email
              ativado
              expCiclo
              avatar
              guid
              aceitaReceberInfo
              aceitaContrato
              pessoa {
                apelido
                telefone
                tipo
                rgInsc
                dataNascimento
                sexo
                estadoCivil
                telefoneFixo
                whatsapp
                telegram
                cep
                logradouro
                enderecoNumero
                complemento
                bairro
                cidade
                uf
                referencia
                documento
              }
            }
          }
        `,
      });
    const { data: { me } } = result;
    return me as User;
  }

  async getById(id: number): Promise<User> {
    const result = await client
      .query({
        variables: {
          id,
        },
        query: gql`
          query ($id: ID!) {
            user(id: $id) {
              id
              code
              name
              email
              avatar
              guid
              ativado
              ativadoRede
              pessoa {
                apelido
                tipo
                telefone
                cidade
                uf
              }
              graduacao {
                id
                nome
              }
              perfil {
                id
                nome
              }
              ciclo {
                graduacao {
                  id
                  nome
                }
              }
            }
          }
        `,
      });
    const { data: { user } } = result;
    return user as User;
  }

  async getSaldos(id: number): Promise<any> {
    const result = await client
      .query({
        variables: {
          id,
        },
        query: gql`
          query ($id: ID!) {
            user(id: $id) {
              saldos
            }
          }
        `,
      });
    const { data: { user: { saldos } } } = result;
    return JSON.parse(saldos);
  }

  async findEquipe(
    variables: PageConsultInput, //{ first: number; page: number; q: string },
  ): Promise<PageConsult<User>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query getUsers(
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            equipe(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                name
                email
                nivel
                code
                createdAt
                ativado
                ativadoRede
                expRede
                avatar
                pessoa {
                  apelido
                  telefone
                  documento
                }
                parent {
                  id
                  code
                }
                perfil {
                  nome
                }
                pgv
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { equipe } } = result;
    return equipe;
  }

  async uploadAvatar(file: File | Blob) {
    await client
      .mutate({
        variables: { file },
        mutation: gql`
          mutation ($file: Upload!) {
            r: updateUserAvatar(file: $file)
          }
        `,
      });
  }

  async updateMe(user: User): Promise<boolean> {
    const input = clone(filterKeys(user, userInputKeys));
    input.pessoa.nome = user.name;
    input.pessoa = filterKeys(input.pessoa, pessoaInputKeys);
    const result = await client
      .mutate({
        variables: {
          input,
        },
        mutation: gql`
          mutation($input: UserInput!) {
            updateMe(input: $input)
          }
        `,
      });
    const { data: { updateMe } } = result;
    return updateMe;
  }

  async changePassword(input: UserChangePassword): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          input,
        },
        mutation: gql`
          mutation($input: UserChangePasswordInput!) {
            changePassword(input: $input)
          }
        `,
      });
    const { data: { changePassword } } = result;
    return changePassword;
  }

  async changePasswordFinanceira(input: UserChangePassword): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          input,
        },
        mutation: gql`
          mutation($input: UserChangePasswordInput!) {
            r: changePasswordFinanceira(input: $input)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async getEnderecos(): Promise<User> {
    const result = await client
      .query({
        query: gql`
          query {
            me {
              id
              code
              name
              pessoa {
                apelido
                cep
                logradouro
                enderecoNumero
                complemento
                bairro
                cidade
                uf
                referencia
                enderecos(excluido: false) {
                  id
                  contato
                  cep
                  logradouro
                  enderecoNumero
                  complemento
                  bairro
                  cidade
                  uf
                  referencia
                  tipo
                }
              }
            }
          }
        `,
      });
    const { data: { me } } = result;
    return me as User;
  }

  async getPerfil(): Promise<PerfilAfiliado> {
    const result = await client
      .query({
        query: gql`
          query {
            me {
              perfil {
                id
                nome
                pp1pedido
                desconto1pedido
                valorMinimo1pedido
                pg1pedido
                pgCiclo
                pu1pedido
                descontoRecompra
                participaGraduacao
                participaViagens
              }
            }
          }
        `,
      });
    const { data: { me: perfil } } = result;
    return perfil as PerfilAfiliado;
  }

  async getPerfis(): Promise<PerfilAfiliado[]> {
    const result = await client
      .query({
        query: gql`
          query {
            p: perfisAfiliados(first: 100) {
              data {
                id
                nome
                pp1pedido
                desconto1pedido
                valorMinimo1pedido
                pg1pedido
                pgCiclo
                pu1pedido
                descontoRecompra
                participaGraduacao
                participaViagens
              }
            }
          }
        `,
      });
    const { data: { p: { data } } } = result;
    return data as PerfilAfiliado[];
  }

  async changePerfilUpgrade(id: number | null): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          perfilId: id,
        },
        mutation: gql`
          mutation($perfilId: ID) {
            r: updateUserPerfilUpgrade(perfilId: $perfilId)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async changePontoApoioCompra(id: number | null): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation($id: ID) {
            r: updatePontoApoioCompra(id: $id)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async changePontoApoioCompraToMe(): Promise<boolean> {
    await http.get("api/pa/local-compra-me");
    return true;
  }

  async getPixKeys(): Promise<PixKey[]> {
    const result = await client
      .query({
        query: gql`
          query {
            me {
              pixKeys {
                chave
                descricao
                status
              }
            }
          }
        `,
      });
    const { data: { me: { pixKeys } } } = result;
    return pixKeys as PixKey[];
  }

  async addPixKey(pixKey: PixKey): Promise<PixKey> {
    const result = await client
      .mutate({
        variables: {
          input: pixKey,
        },
        mutation: gql`
          mutation($input: PixKeyInput!) {
            r: addPixKey(input: $input) {
              chave
              descricao
              status
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r as PixKey;
  }

  async removePixKey(chave: string): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          chave,
        },
        mutation: gql`
          mutation($chave: String!) {
            r: removePixKey(chave: $chave)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }
}

interface BonusTipo {
  name: string;
  descricao: string;
}

export const bonusTipos: Readonly<BonusTipo[]> = Object.freeze([
  { name: "unilevel", descricao: "Unilevel" },
  { name: "primeiro_pedido", descricao: "Primeiro Pedido" },
  { name: "ativacao_mensal", descricao: "Ativação Mensal" },
  { name: "plr", descricao: "PLR" },
  { name: "saque", descricao: "Saque" },
  { name: "pagamento_pedido", descricao: "Pagamento Pedido" },
  { name: "outros", descricao: "Outros" },
]);
