import {
  CrudService,
  initialPaginatorInfo,
  PageConsultInput,
  PaginatorInfo,
  ServiceConsulta,
} from "@/services/Services";
import { onMounted, reactive, ref, watch } from "vue";

import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { clone } from "@/utils/object";

export interface CadastroOptions {
  onReset?: () => void;
  onLoad?: () => void;
}

export function useConsulta<T, G extends PageConsultInput = PageConsultInput>(
  service: ServiceConsulta<T>,
) {
  const data = ref<T[]>([]);
  const paginatorInfo = ref<PaginatorInfo>({ ...initialPaginatorInfo });

  const loading = ref(false);
  const search = reactive<G>({
    first: 25,
    page: 1,
  } as G);

  async function consultar() {
    loading.value = true;
    try {
      const resp = await service.consultar(search);
      // @ts-ignore
      data.value = resp.data;
      paginatorInfo.value = resp.paginatorInfo;
    } finally {
      loading.value = false;
    }
  }

  onMounted(() => {
    watch(
      () => [search.first, search.page, search.sortField, search.sortOrder],
      () => consultar(),
    );
  });

  return {
    loading,
    data,
    search,
    paginatorInfo,
    consultar,
  };
}

export function useCadastro<T extends object>(
  service: CrudService<T>,
  initialObj: T,
  options?: CadastroOptions,
) {
  const data = reactive<T>(clone(initialObj));
  const loading = ref(false);
  const saving = ref(false);
  const toast = useToast();

  return {
    saving,
    loading,
    data,
    reset() {
      Object.assign(data, clone(initialObj));
      options?.onReset && options.onReset();
    },
    async salvar(idEditar?: number) {
      saving.value = true;
      try {
        if (idEditar) {
          await service.atualizar(idEditar, data as T);
          toast.add({
            severity: "success",
            summary: "Salvo com sucesso",
            life: 2_000,
          });
          return true;
        }
        const id = await service.cadastrar(data as T);
        // @ts-ignore
        data.id = id;
        toast.add({
          severity: "success",
          summary: "Cadastrado com sucesso",
          life: 2_000,
        });
        return true;
      } catch (e) {
        return false;
      } finally {
        saving.value = false;
      }
    },
    async load(id: number) {
      loading.value = true;
      try {
        const result = await service.getById(id);
        Object.assign(data, result);
        options?.onLoad && options.onLoad();
      } finally {
        loading.value = false;
      }
    },
  };
}

export function useExcluir<T>(service: CrudService<T>) {
  const toast = useToast();
  const confirm = useConfirm();

  return {
    async confirmarExcluir(
      id: number,
      message = "Tem certeza que deseja excluir?",
    ): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        confirm.require({
          message,
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            try {
              await service.excluir(id);
              toast.add({
                severity: "success",
                summary: "Excluído com sucesso",
                life: 2_000,
              });
              resolve(true);
            } catch (e) {
              console.log("Falhar ao excluir", e);
              reject();
            }
          },
          reject() {
            resolve(false);
          },
        });
      });
    },
  };
}
