import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { key, store } from "./store";
import installPrimeVue from "./primeVue";
import InputmaskDirective from "@/directives/inputmask";
import "./registerServiceWorker";

import "@mdi/font/css/materialdesignicons.min.css";
import "./assets/layout/layout.scss";
import { formatCurrency, formatNumber } from "./utils/format";
import EventBus, { EVT_SW_UPDATE } from "./eventBus";

document.addEventListener("visibilitychange", function () {
  if (document.visibilityState === "visible") {
    EventBus.emit(EVT_SW_UPDATE);
  }
});

const app = createApp(App);
app.use(store, key);
app.use(router);

app.config.globalProperties.$formatCurrency = formatCurrency;
app.config.globalProperties.$formatNumber = formatNumber;

installPrimeVue(app);

// @ts-ignore
const requireComponent = require.context("./components", true, /\.vue$/);
requireComponent.keys().forEach((fileName: string) => {
  const comp = requireComponent(fileName).default;
  comp.name && app.component(comp.name, comp);
});

app.directive("input-mask", InputmaskDirective);

app.mount("#app");
