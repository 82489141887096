import { MutationTree } from "vuex";
import storage from "./storage";
import { State } from "./types";

export enum MutationTypes {
  SET_SESSION = "SET_SESSION",
  SET_USER = "SET_USER",
  SET_CONFIG = "SET_CONFIG",
  LOGOUT = "LOGOUT",
  SET_CARRINHO = "SET_CARRINHO",
  SET_PERFIL_NOVO = "SET_PERFIL_NOVO",
}

export const mutations: MutationTree<State> = {
  [MutationTypes.SET_SESSION](state, value): any {
    storage.setAccessToken(value.access_token);
    storage.setRefreshToken(value.refresh_token);
    state.accessToken = value.access_token;
    state.refreshToken = value.refresh_token;
  },
  [MutationTypes.SET_USER](state, value): any {
    storage.setUser(value);
    state.user = value;
  },
  [MutationTypes.SET_CONFIG](state, value): any {
    state.configuracao = Object.assign(state.configuracao, value);
  },
  [MutationTypes.SET_CARRINHO](state, value): any {
    state.carrinhoTotais = value.totais;
    state.carrinhoItens = value.itens;
  },
  [MutationTypes.LOGOUT](state, value): any {
    storage.setAccessToken("");
    storage.setRefreshToken("");
    state.accessToken = "";
    state.refreshToken = "";
  },
  [MutationTypes.SET_PERFIL_NOVO](state, value): any {
    state.novoPerfil = value;
  },
};
