export interface Pessoa {
  id: number;
  nome: string;
  email: string;
  apelido?: string;
  telefone?: string;
  createdAt?: Date;
  updatedAt?: Date;
  tipo?: string;
  rgInsc?: string;
  dataNascimento?: string;
  sexo?: string;
  estadoCivil?: string;
  telefoneFixo?: string;
  whatsapp?: string;
  telegram?: string;
  cep?: string;
  logradouro?: string;
  enderecoNumero?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  uf?: string;
  referencia?: string;
  documento?: string;
  enderecos?: PessoaEndereco[];
}

export interface PessoaEndereco {
  id: number;
  tipo?: string;
  cep?: string;
  logradouro?: string;
  enderecoNumero?: string;
  complemento?: string;
  referencia?: string;
  bairro?: string;
  cidade?: string;
  cidadeIbge?: string;
  uf?: string;
  contato?: string;
  longitude?: number;
  latitude?: number;
  excluido?: boolean;
}

export const emptyPessoa: Readonly<Pessoa> = Object.freeze({
  id: null!,
  nome: "",
  email: "",
});

export interface PessoaInput {
  id: number;
  nome: string;
  email: string;
  apelido?: string;
  telefone?: string;
  tipo?: string;
  rgInsc?: string;
  dataNascimento?: string;
  sexo?: string;
  estadoCivil?: string;
  telefoneFixo?: string;
  whatsapp?: string;
  telegram?: string;
  cep?: string;
  logradouro?: string;
  enderecoNumero?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  uf?: string;
  referencia?: string;
  documento?: string;
}
export const pessoaInputKeys: Array<keyof PessoaInput> = [
  "id",
  "nome",
  "email",
  "apelido",
  "telefone",
  "tipo",
  "rgInsc",
  "dataNascimento",
  "sexo",
  "estadoCivil",
  "telefoneFixo",
  "whatsapp",
  "telegram",
  "cep",
  "logradouro",
  "enderecoNumero",
  "complemento",
  "bairro",
  "cidade",
  "uf",
  "referencia",
  "documento",
];
