
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import { PontoApoio, PontoApoioService } from "@/services/PontoApoioService";
import { openWhatsapp } from "@/utils/whats";

export function useShowPontoApoio() {
  const data = reactive({
    id: null,
    title: "",
    visible: false,
    show(id: number, title = "Ponto de Apoio") {
      // @ts-ignore
      this.id = id;
      this.title = title;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-ponto-apoio-view",
  emits: ["update:visible"],
  props: {
    id: [Number, String] as PropType<number>,
    title: {
      type: String,
      default: "Ponto de Apoio",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<PontoApoio>();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = await new PontoApoioService().getById(id);
        data.value = result;
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    return {
      openWhatsapp,
      data,
      loading,
      close,
      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },
    };
  },
});
