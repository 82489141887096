export interface PaginatorInfo {
  count: number;
  currentPage: number;
  firstItem: number;
  hasMorePages: boolean;
  lastItem: number;
  lastPage: number;
  perPage: number;
  total: number;
}

export type PageConsult<T> = {
  data: T[];
  paginatorInfo: PaginatorInfo;
};

export type PageConsultInput = {
  first: number;
  page: number;
  q?: string;
  sortField?: string;
  sortOrder?: string;
};

export type DataTableControl = {
  rows: number;
  page: number;
  sortField: string;
  sortOrder: number;
  filter: string;
  totalRecords: number;
};

export const initialPaginatorInfo: Readonly<PaginatorInfo> = Object.freeze({
  count: 0,
  currentPage: 1,
  firstItem: 1,
  hasMorePages: false,
  lastItem: 0,
  lastPage: 0,
  perPage: 10,
  total: 0,
});

export interface Anexo {
  key: string;
  descricao?: string;
  file?: File | Blob;
  fileName?: string;
  url?: string;
  tmp?: boolean;
}

export interface ServiceConsulta<T> {
  consultar: (params: PageConsultInput) => Promise<PageConsult<T>>;
}

export interface CrudService<T> extends ServiceConsulta<T> {
  cadastrar: (data: T) => Promise<number>;

  atualizar: (id: number, data: T) => Promise<void>;

  excluir: (id: number) => Promise<void>;

  getById: (id: number) => Promise<T>;
}
