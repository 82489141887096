import { register } from "register-service-worker";
import { showMessageUpdate } from "./utils/updateApp";
import EventBus, { EVT_SW_UPDATE } from "./eventBus";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}sw.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB",
      );
    },
    registered(reg) {
      console.log("Service worker has been registered.");
      EventBus.on(EVT_SW_UPDATE, () => {
        console.log("SW get update");
        reg.update().catch((err) => {
          console.log("[SW UPDATE ERROR]:" + err);
        });
      });
      // setTimeout(function update() {
      //   console.log("SW get update");
      //   reg.update().catch((err) => {
      //     console.log("[SW UPDATE ERROR]:" + err);
      //   });
      //   setTimeout(update, 10_000);
      // }, 30_000);
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    async updated(registration) {
      console.log("New content is available; please refresh.");
      const newWorker = registration.waiting || registration.installing;
      if (newWorker && (await showMessageUpdate())) {
        newWorker.postMessage({ action: "skipWaiting" });
      }
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode.",
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });

  if (navigator.serviceWorker) {
    let refreshing: boolean;
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }
}
