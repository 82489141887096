<template>
  <div class="public-container">
    <div class="p-d-flex p-flex-column p-ai-center p-jc-start p-jc-sm-center">
      <div class="p-text-center">
        <img alt="Logo" src="assets/images/logo1.png" style="height: 200px" />
      </div>
      <p-card>
        <template #title>Escritório Virtual</template>
        <template #content>
          <form method="POST" @submit.prevent="login">
            <div class="p-fluid">
              <div class="p-field">
                <label for="email">E-mail</label>
                <p-inputtext
                  id="email"
                  type="email"
                  v-model="email"
                  required
                  ref="elEmail"
                  autocomplete="username"
                />
              </div>
              <div class="p-field">
                <label for="password">Senha</label>
                <p-inputtext
                  id="password"
                  type="password"
                  v-model="password"
                  required
                  autocomplete="current-password"
                />
              </div>
            </div>

            <div v-if="msg">
              <p-message severity="warn" :key="msgKey">
                {{ msg }}
              </p-message>
            </div>

            <div class="p-d-flex p-ai-center">
              <div style="flex: 1">
                <a href="#/forgot-password">Esqueceu sua senha?</a>
              </div>
              <p-button
                v-if="loading"
                icon="pi pi-spin pi-spinner"
                label="ACESSAR"
                :disabled="true"
              />
              <p-button v-else label="ACESSAR" type="submit" />
            </div>
          </form>
        </template>
      </p-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { login as doLogin } from "@/services/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Login",
  setup() {
    const email = ref<string>("");
    const password = ref<string>("");
    const msg = ref<string>("");
    const msgKey = ref<number>(0);
    const loading = ref<boolean>(false);
    const router = useRouter();
    const elEmail = ref();

    async function login() {
      if (loading.value) {
        return;
      }
      loading.value = true;
      try {
        await doLogin(email.value, password.value);
        router.push("/");
      } catch (e) {
        msgKey.value++;
        msg.value =
          "Não foi possível efetuar o login. Verifique se o usuário e senha estão corretos";
      } finally {
        loading.value = false;
      }
    }

    function forgotPassword() {
      console.log("doit");
    }

    onMounted(() => {
      elEmail.value?.$el.focus();
    });

    return {
      email,
      password,
      login,
      msg,
      msgKey,
      loading,
      forgotPassword,
      elEmail,
    };
  },
});
</script>
