
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { http } from "@/api/rest";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "x-verificar-email",
  setup() {
    const enviado = ref(false);
    const sending = ref(false);
    const checking = ref(false);
    const dialog = ref(false);
    const codigo = ref("");

    const toast = useToast();

    return {
      enviado,
      sending,
      checking,
      codigo,
      dialog,
      async enviar() {
        sending.value = true;
        try {
          await http.get("api/users/check-email");
          toast.add({
            severity: "success",
            summary: "Código enviado",
            life: 3000,
          });
          enviado.value = true;
        } finally {
          sending.value = false;
        }
      },
      async validar() {
        checking.value = true;
        try {
          await http.post("api/users/check-email", {
            code: codigo.value,
          });
          document.location.reload();
        } finally {
          checking.value = false;
        }
      },
    };
  },
});
