<template>
  <p-dialog
    header="Pontos de Graduação"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 800px"
  >
    <div class="p-text-center" v-if="loading">
      <p-progress-spinner style="width: 50px; height: 50px" />
    </div>
    <div v-else-if="data">
      <div class="info p-text-center">
        <label>ID:</label>
        <span>{{ data.me.code }}</span>
      </div>
      <div class="info p-text-center">
        <span>{{ data.me.name }}</span>
      </div>

      <div
        class="info p-text-center p-mt-3"
        style="color: red;"
        v-if="!data.me.ativado && data.me.perfil?.nome === 'EXECUTIVO'"
      >Para receber a qualificação alcançada é necessário estar ATIVO no ciclo.</div>

      <div
        class="info p-text-center p-mt-3"
        style="color: red;"
        v-if="data.me.perfil?.nome !== 'EXECUTIVO'"
      >Para participar do plano de carreira e receber as qualificações é necessário adquirir o plano de negócios EXECUTIVO.</div>

      <div class="info p-text-center p-mt-5">
        <span>CICLO {{ `${data.cicloMes}`.padStart(2, '0') }}/{{ data.cicloAno }}</span>
      </div>

      <div class="p-d-flex p-ai-center p-flex-row graduacao-center">
        <div class="p-text-center graduacao-pin" v-if="pinStart">
          <img :src="pinStart" />
        </div>
        <div class="graduacao-link">
          <img
            style="max-width: 70px; align-self: center"
            src="/img/z8-link-4.png"
          />
        </div>
        <div class="p-d-flex p-flex-column graduacao-progress">
          <div class="p-text-center p-mb-1" style="font-weight: bold">
            <span>{{ $formatNumber(data.pontosValidos, 0) }} PONTOS VÁLIDOS</span>
          </div>
          <div>
            <p-progress-bar
              :value="progresso"
            >{{ $formatNumber(progresso, 2) }}%</p-progress-bar>
          </div>
          <div class="p-text-right" v-if="data.qualificacaoProxima">
            <small>{{ $formatNumber(data.qualificacaoProxima?.pg || 0, 0) }}</small>
          </div>
        </div>
        <div class="p-text-center graduacao-pin" v-if="pinEnd">
          <img :src="pinEnd" />
        </div>
      </div>

      <p-datatable
        ref="dt"
        :value="data.detalhes"
        data-key="user"
        stripedRows
        breakpoint="100px"
        class="graduacao-detalhes"
      >
        <template #empty>
          <div class="p-text-center p-mt-5 p-mb-5">Nenhum registro!</div>
        </template>

        <p-column style="min-width: 150px" header="Pontuação Por Equipe">
          <template #body="{ data }">
            <span v-if="data.descricao">{{ data.descricao }}</span>
            <a
              v-else
              style="cursor: pointer"
              @click="userView.show(data.user.id)"
            >
              {{
                data.user?.name
              }}
            </a>
          </template>
        </p-column>
        <p-column field="pontos" style="text-align: right;width: 25%">
          <template #header>
            <div class="p-text-center">Pontos Gerados Equipe</div>
          </template>
          <template #body="{ data }">{{ $formatNumber(data.pontos, 0) }}</template>
        </p-column>
        <p-column field="pontos" header style="text-align: right;width: 25%">
          <template #header>
            <div class="p-text-center">
              Pontos Aproveitáveis
              <br />
              <small
                style="font-weight: normal"
              >VME: {{ data.qualificacaoProxima?.vme }}</small>
            </div>
          </template>
          <template #body="{ data }">{{ $formatNumber(data.validos, 0) }}</template>
        </p-column>
      </p-datatable>

      <div v-if="pontosFaltantes > 0" class="p-text-center p-mt-5">
        Faltam
        <b>{{ $formatNumber(pontosFaltantes, 0) }}</b> pontos válidos para o
        <b>{{ data.qualificacaoProxima?.nome }}</b>
      </div>
    </div>

    <div class="p-d-flex p-jc-start p-mt-5" v-if="data">
      <div style="flex: 1" class="p-d-flex p-ai-center"></div>
      <p-button
        label="Fechar"
        class="p-button-secondary p-mr-2"
        @click="close"
      />
    </div>
  </p-dialog>

  <x-user-view v-model:visible="userView.visible" :id="userView.id" />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, ref, watch, computed } from "vue";
import {
  User,
  Graduacao,
  getPinByGraduacao,
} from "@/services/UserService";
import { http } from "@/api/rest";
import { useShowUser } from "@/components/UserView.vue";

interface Info {
  me: User
  qualificacaoAtual?: Graduacao
  qualificacaoProxima?: Graduacao
  cicloMes: number
  cicloAno: number
  pontosValidos: number
  detalhes: {
    pontos: number
    validos: number
    user: User
    descricao: string
  }[]
}

export function useShowUserGraduacao() {
  const data = reactive({
    id: null,
    visible: false,
    show(id: number) {
      // @ts-ignore
      this.id = id;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-user-graduacao-view",
  emits: ["update:visible"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<Info>();
    const userView = useShowUser();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = (await http.get(`api/user-graduacao-info?user=${id}`)).data;
        data.value = result;
        if (data.value?.detalhes?.[0]) {
          data.value.detalhes[0].descricao = "PONTOS PRÓPRIOS";

          const linhas = data.value.detalhes;

          data.value?.detalhes.push({
            user: null!,
            descricao: `${linhas.length - 1} linha(s)`,
            pontos: linhas.reduce((tot, r) => tot + r.pontos, 0),
            validos: linhas.reduce((tot, r) => tot + r.validos, 0)
          });
        }
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    const pinStart = computed(() => {
      return data.value
        ? getPinByGraduacao(data.value.me, data.value.qualificacaoAtual?.nome || "")
        : "";
    });
    const pinEnd = computed(() => {
      return data.value
        ? getPinByGraduacao(data.value.me, data.value.qualificacaoProxima?.nome || "")
        : "";
    });
    const progresso = computed(() => {
      if (data.value) {
        return data.value.pontosValidos / (data.value.qualificacaoProxima?.pg || 1) * 100;
      }
      return 0;
    });

    const pontosFaltantes = computed(() => {
      const validos = data.value?.pontosValidos || 0;
      const pg = data.value?.qualificacaoProxima?.pg || 0;
      return pg - validos;
    });

    return {
      data,
      loading,
      pinStart,
      pinEnd,
      pontosFaltantes,
      userView,
      progresso,

      close,

      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },
    };
  },
});
</script>

<style scoped>
.info {
  margin: 5px;
}
.info label {
  margin-right: 5px;
}
.info span {
  font-weight: bold;
}
.graduacao-detalhes tr:last-of-type {
  font-weight: bold !important;
}

.graduacao-progress {
  flex: 1;
  margin: 0 20px;
  width: 100%;
}

.graduacao-link {
  display: none;
}

.graduacao-pin img {
  max-width: 150px;
  align-self: center;
}

@media (max-width: 600px) {
  .graduacao-center {
    flex-wrap: wrap;
  }
  .graduacao-pin {
    flex: 1;
  }
  .graduacao-pin img {
    max-width: 120px;
  }
  .graduacao-progress {
    order: 3;
    flex: 1 1 100%;
    /* flex-shrink: 100%; */
  }
  .graduacao-link {
    display: unset;
  }
}

@media (max-width: 350px) {
  .graduacao-pin img {
    max-width: 100px;
  }
}
</style>