import { createApp, defineComponent, h, nextTick, ref } from "vue";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import installPrimeVue from "@/primeVue";

const genContainer = () => {
  return document.createElement("div");
};

const Message = defineComponent({
  emits: ["ok"],
  render() {
    return [
      h(
        "div",
        { class: "p-text-center p-mt-4" },
        "O sistema possui uma nova versão.",
      ),
      h(
        "div",
        { class: "p-text-center p-mt-1" },
        "Clique em OK para atualizar!",
      ),
      h(
        "div",
        { class: "p-text-center p-mt-4" },
        h(Button, {
          onClick: () => {
            this.$emit("ok");
          },
          label: "OK",
        }),
      ),
    ];
  },
});

export async function showMessageUpdate(): Promise<Boolean> {
  return new Promise((resolve) => {
    const container = genContainer();
    const app = createApp(defineComponent({
      setup() {
        const visible = ref(true);

        async function finalizar() {
          await nextTick();
          app.unmount();
          container.remove();
        }

        return () =>
          h(Sidebar, {
            dismissable: false,
            showCloseIcon: false,
            position: "bottom",
            visible: visible.value,
          }, () =>
            h(Message, {
              onOk() {
                visible.value = false;
                finalizar();
                resolve(true);
              },
            }));
      },
    }));
    installPrimeVue(app);
    app.mount(container);
    document.body.appendChild(container);
  });
}
