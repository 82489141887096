import {
  createRouter,
  createWebHashHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
  useRoute,
} from "vue-router";
import Layout from "../components/layout/index.vue";
import Dashboard from "../pages/dashboard/index.vue";
import Login from "../pages/Login.vue";
import ForgotPassword from "../pages/ForgotPassword.vue";
import PageNotFound from "../pages/PageNotFound.vue";
import { store } from "../store";
import { authorize, authorizeCallback } from "@/services/auth";
import { defineComponent, h, onMounted } from "vue";

function checkAdmin(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (!store.state.user?.admin) {
    return next({ path: "/forbiden" });
  }
  next();
}

function checkPa(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (!store.state.user?.temPontoApoio) {
    return next({ path: "/forbiden" });
  }
  next();
}

const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: "/admin/itens",
    name: "admin-itens",
    component: () => import("../pages/admin/itens/index.vue"),
    beforeEnter: checkAdmin,
  },
  {
    path: "/admin/estoque",
    name: "admin-estoque",
    component: () => import("../pages/admin/itens/estoque.vue"),
    beforeEnter: checkAdmin,
  },
  {
    path: "/admin/categorias",
    name: "admin-categorias",
    component: () => import("../pages/admin/categorias/index.vue"),
    beforeEnter: checkAdmin,
  },
  {
    path: "/admin/unidade-medidas",
    name: "admin-unidade-medidas",
    component: () => import("../pages/admin/unidadeMedidas/index.vue"),
    beforeEnter: checkAdmin,
  },
  {
    path: "/admin/embalagens",
    name: "admin-embalagens",
    component: () => import("../pages/admin/embalagens/index.vue"),
    beforeEnter: checkAdmin,
  },
  {
    path: "/admin/pedidos",
    name: "admin-pedidos",
    component: () => import("../pages/admin/pedidos/index.vue"),
    beforeEnter: checkAdmin,
  },
  {
    path: "/admin/saques",
    name: "admin-saques",
    component: () => import("../pages/admin/saques/index.vue"),
    beforeEnter: checkAdmin,
  },
  {
    path: "/admin/pontos-apoio",
    name: "admin-pontos-apoio",
    component: () => import("../pages/admin/pontosApoio/index.vue"),
    beforeEnter: checkAdmin,
  },
];

const paRoutes: Array<RouteRecordRaw> = [
  {
    path: "/pa/estoque",
    name: "pa-estoque",
    component: () => import("../pages/pa/itens/index.vue"),
    beforeEnter: checkPa,
  },
  {
    path: "/pa/pedidos",
    name: "pa-pedidos",
    component: () => import("../pages/pa/pedidos/index.vue"),
    beforeEnter: checkPa,
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Layout,
    children: [
      {
        path: "/",
        redirect: {
          name: "dashboard",
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/equipe",
        name: "equipe",
        component: () => import("../pages/equipe/index.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import("../pages/Account.vue"),
      },
      {
        path: "/change-password",
        name: "change-password",
        component: () => import("../pages/ChangePassword.vue"),
      },
      {
        path: "/change-password-financeira",
        name: "change-password-financeira",
        component: () => import("../pages/ChangePasswordFinanceira.vue"),
      },
      {
        path: "/pix-keys",
        name: "pix-keys",
        component: () => import("../pages/PixKeys.vue"),
      },
      {
        path: "/saques",
        name: "saques",
        component: () => import("../pages/saques/index.vue"),
      },
      {
        path: "/loja",
        name: "loja",
        component: () => import("../pages/Loja.vue"),
      },
      {
        path: "/cart",
        name: "cart",
        component: () => import("../pages/cart/index.vue"),
      },
      {
        path: "/checkout",
        name: "checkout",
        component: () => import("../pages/checkout/index.vue"),
      },
      {
        path: "/pedidos",
        name: "pedidos",
        component: () => import("../pages/pedidos/index.vue"),
      },
      {
        path: "/pedido/:id",
        name: "pedido-detalhes",
        component: () => import("../pages/pedidos/Detalhes.vue"),
      },
      {
        path: "/pedido/:id/checkout",
        name: "pedido-checkout",
        component: () => import("../pages/checkout/pagamento.vue"),
      },
      {
        path: "/change-perfil",
        name: "change-perfil",
        component: () => import("../pages/ChangePerfil.vue"),
      },
      {
        path: "/change-pa",
        name: "change-pa",
        component: () => import("../pages/ChangePontoApoio.vue"),
      },
      {
        path: "/extrato-financeiro",
        name: "extrato-financeiro",
        component: () => import("../pages/ExtratoFinanceiro.vue"),
      },
      {
        path: "/extrato-pontos",
        name: "extrato-pontos",
        component: () => import("../pages/ExtratoPontos.vue"),
      },
      {
        path: "/pontos-apoio",
        name: "pontos-apoio",
        component: () => import("../pages/PontosApoio.vue"),
      },
      ...paRoutes,
      ...adminRoutes,
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      public: true,
    },
  },
  // {
  //   path: "/auth",
  //   name: "auth",
  //   component: defineComponent({
  //     setup() {
  //       const router = useRoute();
  //       onMounted(() => {
  //         authorizeCallback(router.query);
  //       });
  //       return () => null;
  //     },
  //   }),
  //   meta: {
  //     public: true,
  //   },
  // },
  {
    path: "/cadastro/:hash",
    name: "cadastro",
    component: () => import("../pages/cadastro/index.vue"),
    meta: {
      public: true,
    },
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound, meta: { public: true } },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  if (store.getters.isAuthenticated && to.name === "login") {
    next({ name: "dashboard" });
    return;
  }
  if (!isPublic && !store.getters.isAuthenticated) {
    // authorize();
    next({ name: "login" });
    return;
  }
  next();
});

export default router;
