
import { useRouter } from "vue-router";
import { confirmLogout } from "@/services/auth";
import { defineComponent } from "vue";
import Avatar from "../Avatar.vue";

export default defineComponent({
  emits: ["menuitem-click"],
  components: { Avatar },
  data() {
    return {
      expanded: false,
    };
  },
  setup(_, { emit }) {
    const router = useRouter();
    return {
      logout() {
        confirmLogout();
      },
      account(event: Event) {
        emit("menuitem-click", {
          originalEvent: event,
          item: {},
        });
        router.push({ name: "account" });
      },
    };
  },
  methods: {
    onClick(event: Event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
  },
});
