<template>
  <div class="layout-topbar" :style="isDev ? 'background: red' : ''">
    <div class="p-d-flex">
      <button class="p-link layout-menu-button" @click="onMenuToggle">
        <span class="pi pi-bars"></span>
      </button>
      <p-tag v-if="isDev" severity="warning" class="p-ml-1">SANDBOX</p-tag>
    </div>
    <div
      class="p-d-flex p-jc-center p-ai-center"
      style="flex: 1; position: relative"
    >
      <button
        class="p-link p-d-flex p-jc-center p-ai-center p-ml-1"
        @click="toggleMenuUser"
        style="color: white"
      >
        <Avatar style="width: 30px; height: 30px" />
        <span class="p-ml-2 user-name">
          {{ $store.state.user?.pessoa.apelido || $store.state.user?.name }}
        </span>
        <Menu ref="menu" :model="items" :popup="true" />
      </button>
    </div>
    <div class="layout-topbar-icons">
      <button class="p-link" @click="gotToCart">
        <span class="layout-topbar-item-text">Carrinho de Compras</span>
        <span class="layout-topbar-icon pi pi-shopping-cart"></span>
        <span
          v-if="$store.state.carrinhoTotais.qtdItens"
          class="layout-topbar-badge"
          >{{ $store.state.carrinhoTotais.qtdItens }}</span
        >
      </button>
      <button class="p-link">
        <span class="layout-topbar-item-text">Notificações</span>
        <span class="layout-topbar-icon pi pi-bell"></span>
        <span
          v-if="$store.state.user?.unreadNotifications"
          class="layout-topbar-badge"
          >{{ $store.state.user.unreadNotifications }}</span
        >
      </button>
      <button class="p-link" @click="logout">
        <span class="layout-topbar-item-text">Sair</span>
        <span class="layout-topbar-icon pi pi-sign-out"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { confirmLogout } from "@/services/auth";
import Avatar from "../Avatar.vue";
import { menusUser } from "@/menu";

export default {
  components: {
    Avatar,
  },
  data() {
    return {
      items: menusUser,
      isDev: process.env.VUE_APP_SANDBOX === "true",
    };
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    logout() {
      confirmLogout();
    },
    toggleMenuUser(evt) {
      this.$refs.menu.toggle(evt);
    },
    gotToCart() {
      this.$router.push({ name: "cart" });
    },
  },
};
</script>

<style scoped>
.user-name {
  text-overflow: ellipses;
  white-space: nowrap;
  font-weight: bold;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 800px) {
  .user-name {
    max-width: 150px;
  }
}
@media (max-width: 300px) {
  .user-name {
    max-width: 50px;
  }
}
</style>