<template>
  <div class="x-form-item p-d-flex p-flex-wrap p-ai-center p-p-3">
    <div style="flex: 1" class="p-component">{{ descricao }}</div>
    <div class="p-text-right">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "x-form-item",
  props: {
    descricao: {
      type: String,
      default: "",
    },
    detalhes: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.x-form-item {
  position: relative;
  border-top: 1px solid #dee2e6;
}
/* .s-form-item:not(.s-form-item:first-child) {
  border-top: 1px solid #dee2e6;
} */
</style>
