<template>
  <InputMask
    :modelValue="localValue"
    @change="changeLocalValue"
    mask="99/99/9999"
    slotChar="dd/mm/yyyy"
    placeholder="dd/mm/yyyy"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "@vue/runtime-core";

export default defineComponent({
  name: "x-input-date",
  inheritAttrs: true,
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const localValue = ref(fmtValue(props.modelValue));
    watch(
      () => props.modelValue,
      (v) => (localValue.value = fmtValue(v))
    );

    /**
     * yyyy-mm-dd to dd/mm/yyyy
     */
    function fmtValue(s?: string) {
      if (!s) {
        return null;
      }
      return s.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1");
    }

    /**
     *  dd/mm/yyyy to yyyy-mm-dd
     */
    function changeLocalValue(e: any) {
      const d = e.target.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1");
      localValue.value = isNaN(new Date(d).getTime()) ? null : d;
      emit("update:modelValue", localValue.value);
      if (!localValue.value) {
        e.target.value = "";
      }
    }

    return {
      localValue,
      changeLocalValue,
    };
  },
});
</script>
