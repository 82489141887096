
import { defineComponent, PropType } from "@vue/runtime-core";
import { getService as getServiceUser, User } from "@/services/UserService";

export default defineComponent({
  name: "x-foreign-user",
  emits: ["update:modelValue"],
  props: {
    modelValue: Object as PropType<User>,
  },
  setup(props, { emit }) {
    function fmt(e: User | null) {
      return e ? `${e.code} - ${e.name} - ${e.pessoa.documento}` : "";
    }
    return {
      fmt,
      change(evt: any) {
        emit("update:modelValue", evt);
      },
      serviceUser: getServiceUser(),
    };
  },
});
