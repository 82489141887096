import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { Anexo, CrudService, PageConsult, PageConsultInput } from "./Services";
import { baseURL } from "@/api/rest";
import { clone, filterKeys, removeKeys } from "@/utils/object";
import { Categoria } from "./CategoriaService";
import { UnidadeMedida } from "./UnidadeMedidaService";
import { store } from "@/store";
import { PerfilAfiliado } from "./PerfilAfiliadoService";

let instance: ItemService;

export const status = ["ativo", "inativo", "indisponível"];

export interface Item {
  id: number;
  codigo: string;
  nome: string;
  valor: number;
  createdAt?: Date;
  updatedAt?: Date;
  avatar?: string;
  imagens?: Anexo[];
  categoriaId?: number;
  categoria: Categoria;
  codigoBarras?: string;
  unidadeMedidaId?: number;
  unidadeMedida: UnidadeMedida;
  peso?: number;
  cubagem?: number;
  valorCusto?: number;
  valorAfiliado?: number;
  fatorPontuacao?: number;
  descricao?: string;
  estoque?: number;
  status?: string;
  perfilAfiliadoId?: number;
  perfilAfiliado?: PerfilAfiliado;
}

export const initialItem: Readonly<Item> = Object.freeze({
  id: null!,
  codigo: null!,
  nome: null!,
  valor: null!,
  createdAt: null!,
  updatedAt: null!,
  avatar: null!,
  imagens: [],
  estoque: null!,
  categoria: null!,
  categoriaId: null!,
  codigoBarras: null!,
  unidadeMedidaId: null!,
  unidadeMedida: null!,
  peso: null!,
  cubagem: null!,
  valorCusto: null!,
  valorAfiliado: null!,
  fatorPontuacao: null!,
  descricao: null!,
  status: null!,
  perfilAfiliado: null!,
  perfilAfiliadoId: null!,
});

export function getInstance(): ItemService {
  if (!instance) {
    instance = new ItemService();
  }
  return instance;
}

export interface ItemConsultInput extends PageConsultInput {
  somenteAtivos?: boolean;
  categorias?: number[];
  disponiveisPrimeiro?: boolean;
}

export const baseUrlImagens = `${baseURL}/api/files/-`;

export function calcValorAfiliado(item: Item): number {
  const state = store.state;
  const primeiroPedido = !state.user?.primeiroPedido || state.novoPerfil;
  let desconto = item.categoria.descontoAfiliado || 0;
  if (
    !state.user?.admin && primeiroPedido && item.categoria.descontoNo1pedido
  ) {
    desconto = state.novoPerfil?.desconto1pedido ||
      state.user?.perfil?.desconto1pedido || 0;
  }

  let valor = desconto
    ? item.valor - (item.valor * (desconto / 100))
    : item.valor;
  // @ts-ignore
  if (store.getters.isCompraPa) {
    const descPa = item.categoria.descontoAdicionalPa || 0;
    valor = valor - (valor * (descPa / 100));
  }
  return Math.round(valor * 100) / 100;
}

export function calcPontos(item: Item, valor: number): number {
  const desconto = item.categoria.descontoAfiliado;
  const val = desconto
    ? item.valor - (item.valor * (desconto / 100))
    : item.valor;
  const fp = item.fatorPontuacao ||
    item.categoria?.fatorPontuacao ||
    0;
  return fp > 0 ? (val || 0) / fp : 0;
}

export default class ItemService implements CrudService<Item> {
  getUrlImagem(item: Item): string | null {
    return item.imagens?.[0]
      ? `${baseUrlImagens}/${item.imagens[0]?.url}`
      : null;
  }

  async consultar(
    variables: ItemConsultInput,
  ): Promise<PageConsult<Item>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query getItens(
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
            $somenteAtivos: Boolean
            $disponiveisPrimeiro: Boolean
            $categorias: [Int!]
          ) {
            itens(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
              somenteAtivos: $somenteAtivos
              categorias: $categorias
              disponiveisPrimeiro: $disponiveisPrimeiro
            ) {
              data {
                id
                codigo
                nome
                valor
                valorAfiliado
                imagens
                updatedAt
                createdAt
                categoria {
                  id
                  nome
                  fatorPontuacao
                  descontoAfiliado
                  descontoNo1pedido
                  descontoAdicionalPa
                }
                status
                estoque
                fatorPontuacao
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { itens } } = result;
    itens.data.forEach((i: any) => {
      i.imagens = JSON.parse(i.imagens);
    });
    return itens;
  }

  private prepareData(input: Item) {
    const data = filterKeys(clone(input), [
      "codigo",
      "nome",
      "valor",
      "estoque",
      "codigoBarras",
      "peso",
      "cubagem",
      "valorCusto",
      "valorAfiliado",
      "fatorPontuacao",
      "descricao",
      "status",
    ]);
    data.categoriaId = input.categoria?.id || null;
    data.unidadeMedidaId = input.unidadeMedida?.id || null;
    data.perfilAfiliadoId = input.perfilAfiliado?.id || null;
    data.imagens = JSON.stringify(input.imagens);
    return data;
  }

  async cadastrar(input: Item): Promise<number> {
    const result = await client
      .mutate({
        variables: {
          input: this.prepareData(input),
        },
        mutation: gql`
          mutation($input: ItemInput!) {
            createItem(input: $input)
          }
        `,
      });
    const { data: { createItem } } = result;
    return createItem;
  }

  async atualizar(id: number, data: Item) {
    await client
      .mutate({
        variables: {
          id,
          input: this.prepareData(data),
        },
        mutation: gql`
          mutation($id: ID!, $input: ItemInput!) {
            updateItem(id: $id input: $input)
          }
        `,
      });
  }

  async excluir(id: number) {
    await client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation($id: ID!) {
            deleteItem(id: $id) { id }
          }
        `,
      });
  }

  async getById(id: number): Promise<Item> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            item(id: $id) {
              id
              createdAt
              updatedAt
              codigo
              nome
              valor
              imagens
              categoriaId
              categoria {
                id
                nome
                fatorPontuacao
                descontoAfiliado
                descontoNo1pedido
                descontoAdicionalPa
              }
              estoque
              codigoBarras
              peso
              cubagem
              valorCusto
              valorAfiliado
              fatorPontuacao
              descricao
              status
              unidadeMedida {
                id
                sigla
                descricao
              }
              perfilAfiliado {
                id
                nome
              }
            }
          }
        `,
      });
    const { data: { item } } = result;
    item.imagens = JSON.parse(item.imagens);
    return item;
  }
}
