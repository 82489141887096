<template>
  <img v-if="avatar" :src="avatar" />
  <img v-else src="@/assets/user-placeholder.png" />
</template>

<script lang="ts">
import { getUrlAvatar, User } from "@/services/UserService";
import { useStore } from "@/store";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  name: "x-avatar",
  props: {
    user: Object as PropType<User>,
  },
  setup({ user }) {
    const store = useStore();
    return {
      avatar: computed(() => {
        if (user) {
          return user.avatar ? getUrlAvatar(user) : "";
        }
        return store.state.user?.avatar ? getUrlAvatar(store.state.user) : "";
      }),
    };
  },
});
</script>

<style scoped>
img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
</style>