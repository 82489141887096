/**
 * @param date No formato yyyy-mm-dd
 * @returns
 */
export function formatDate(date: string) {
  return new Date(date).toLocaleDateString(undefined, {
    timeZone: "utc",
  });
}

export function formatNumber(num: number, decimals = 2) {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function formatCurrency(value: number) {
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}
