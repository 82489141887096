import {
  getInstance as getServiceCarrinho,
  ItemCarrinho,
} from "@/services/CarrinhoService";
import { getInstance } from "@/services/ConfiguracaoService";
import { getService } from "@/services/UserService";
import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./types";

export enum ActionTypes {
  BOOT = "BOOT",
  LOAD_ME = "LOAD_ME",
  UPDATE_CARRINHO = "UPDATE_CARRINHO",
  UPDATE_CONFIG = "UPDATE_CONFIG",
}

export const actions: ActionTree<State, State> = {
  async [ActionTypes.LOAD_ME](ctx) {
    const me = await getService().me();
    ctx.commit(MutationTypes.SET_USER, me);
    // if (me.perfilUpgrade) {
    ctx.commit(MutationTypes.SET_PERFIL_NOVO, me.perfilUpgrade);
    // }
  },
  async [ActionTypes.BOOT](ctx) {
    await ctx.dispatch(ActionTypes.LOAD_ME);
    await ctx.dispatch(ActionTypes.UPDATE_CONFIG);
    ctx.dispatch(ActionTypes.UPDATE_CARRINHO);
  },
  async [ActionTypes.UPDATE_CARRINHO](ctx) {
    ctx.commit(
      MutationTypes.SET_CARRINHO,
      await getServiceCarrinho().get(),
    );
  },
  async [ActionTypes.UPDATE_CONFIG](ctx) {
    const cfg = await getInstance().get([
      "descontoPrimeiroPedido",
      "ppPrimeiroPedido",
      "valorMinimoPrimeiroPedido",
      "ppMensal",
    ]);
    ctx.commit(MutationTypes.SET_CONFIG, cfg);
  },
};
