import { ObjectDirective } from "vue";
import Inputmask from "inputmask";

const InputMaskDirective: ObjectDirective = {
  mounted(el, binding) {
    Inputmask(binding.value, { placeholder: " ", keepStatic: true }).mask(el);
  },
  updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      Inputmask(binding.value, { placeholder: " ", keepStatic: true }).mask(el);
    }
  },
  beforeUnmount(el) {
    el.inputmask && el.inputmask.remove();
  },
};

export default InputMaskDirective;
