import { logout } from "@/services/auth";
import { showMessage } from "@/utils/message";
import {
  ApolloClient,
  // createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client/core";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { store } from "../store";

const uri = `${process.env.VUE_APP_BASE_URL}/gql`;

const handleMessages = (
  { message, detail }: { message: string; detail: string },
) => {
  showMessage("error", `${message}: ${detail}`);
};

// const httpLink = createHttpLink({
//   uri,
// });
const httpLink = createUploadLink({
  uri,
});

// const timeoutLink = new ApolloLinkTimeout(12 * 1000);
// const timeoutHttpLink = timeoutLink.concat(httpLink);

const authLink = setContext(async () => {
  let accessToken = "";

  if (store.getters.isAuthenticated) {
    // if (!isTokenValid(store.getters.accessToken)) {
    //   await store.dispatch("refresh");
    // }
    accessToken = store.state.accessToken;
  }

  return {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const errorLink = onError((p) => {
  if (p?.networkError) {
    // @ts-ignore
    const statusCode = p.networkError?.statusCode;
    if (statusCode === 401) {
      logout();
      return;
    }
    console.error(p);
    handleMessages(
      { message: "Algo deu errado", detail: "Tente em alguns instantes" },
    );
    return;
  }

  if (!p?.graphQLErrors || !p?.graphQLErrors[0]) {
    return;
  }

  const e = p?.graphQLErrors[0];
  if (e?.extensions?.category === "validation") {
    const s = Object.values(e.extensions.validation).map((v) => {
      return Array.isArray(v) ? v.join(". ") : v;
    });
    handleMessages(
      { message: "As informações estão incompletas", detail: s.join(" ") },
    );
    return;
  }

  if (e?.extensions?.category === "authorization") {
    handleMessages(
      { message: "Sem autorização", detail: "Usuário sem acesso a sessa área" },
    );
    return;
  }

  handleMessages(
    { message: "Algo deu errado", detail: "Tente em alguns instantes" },
  );
});

const cache = new InMemoryCache({});

export const client = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    httpLink,
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      // errorPolicy: 'all',
    },
    query: {
      fetchPolicy: "no-cache",
      // errorPolicy: 'all',
    },
  },
});
