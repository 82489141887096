import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import storage from "./storage";
import { State } from "./types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters, GettersType } from "./getters";

export const key: InjectionKey<Store<State>> = Symbol();

interface MyStore<S> extends Store<S> {
  readonly getters: GettersType<S>;
}

export const store = createStore<State>({
  state() {
    return {
      accessToken: storage.accessToken(),
      refreshToken: storage.refreshToken(),
      user: storage.getUser(),
      configuracao: {},
      carrinhoTotais: {
        total: 0,
        qtdItens: 0,
        totalPontos: 0,
      },
      carrinhoItens: [],
      novoPerfil: null!,
    };
  },
  mutations,
  actions,
  getters,
}) as MyStore<State>;

export function useStore() {
  return baseUseStore(key);
}
