import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { User } from "./UserService";
import { Pedido } from "./PedidoService";

export interface InfoResult {
  dashboard: {
    totalPedidos: number;
    totalEquipe: number;
    saldos: any;
    equipe: any;
  };
  me: User;
  equipe: {
    data: User[];
  };
  pedidos: {
    data: Pedido[];
  };
}

export async function loadInfo(): Promise<InfoResult> {
  const result = await client
    .query({
      query: gql`
          query {
            dashboard {
              totalPedidos
              totalEquipe
              saldos
              equipe
            }
            me {
              pv
              primeiroPedido
              ativadoRede
              expRede
              ativado
              expCiclo
              emailVerifiedAt
              graduacao {
                id
                nome
              }
              perfil {
                id
                nome
              }
              ciclo {
                cicloMes
                cicloAno
                pp
                pu
                pg
                pv
                pgv
                graduacao {
                  id
                  nome
                }
              }
            }
            equipe(
              first: 10
              orderBy: {
                column: "e.id"
                order: DESC
              }
            ) {
              data {
                id
                name
                nivel
                avatar
                createdAt
                ativado
                ativadoRede
                perfil {
                  nome
                }
                graduacao {
                  nome
                }
                pessoa {
                  telefone
                }
              }
            }
          }
        `,
    });
  const { data } = result;
  if (data.dashboard.saldos) {
    data.dashboard.saldos = JSON.parse(data.dashboard.saldos);
  }
  if (data.dashboard.equipe) {
    data.dashboard.equipe = JSON.parse(data.dashboard.equipe);
  }
  return data;
}

export async function getSaldos(): Promise<any> {
  const result = await client
    .query({
      query: gql`
          query {
            dashboard {
              saldos
            }
          }
        `,
    });
  const { data: { dashboard: { saldos } } } = result;
  return JSON.parse(saldos);
}
