import axios from "axios";
import { store } from "../store";
import { showMessage } from "@/utils/message";

export const baseURL = process.env.VUE_APP_BASE_URL;

export const http = axios.create({
  baseURL,
  timeout: 10_000,
});

http.interceptors.request.use(function (config) {
  config.headers.authorization = `Bearer ${store.state.accessToken}`;
  return config;
});

http.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (error.response.status === 429) {
      showMessage(
        "error",
        "Detectamos muitas requisições vindas do seu IP, aguarde um instante",
      );
      return Promise.reject(error);
    }

    if (error.response.status === 404) {
      showMessage("error", "Página não encontrada");
      return Promise.reject(error);
    }

    if (error.response.status === 422) {
      // let errors = "";
      // for (var [, value] of Object.entries(error.response.data.errors)) {
      //   errors += `<span class="errors-item">* ${value}</span><br>`;
      // }
      showMessage("error", Object.values(error.response.data.errors).join(" "));
      return Promise.reject(error);
    }

    if (error.response.status === 423) {
      showMessage(
        "error",
        "Este registro não pode ser modificado." + error.response.data.message,
      );
      return Promise.reject(error);
    }

    if (error.response.status === 500 && error.response.data?.message) {
      showMessage("error", error.response.data.message);
      return Promise.reject(error);
    }

    showMessage("error", `Retorno do servidor: ${error.response.status}`);
    return Promise.reject(error);
  }

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    showMessage("error", "Erro ao efetuar requisição ao servidor");
  } else {
    // Something happened in setting up the request that triggered an Error
    showMessage("error", error.message);
  }
  console.log(error.config);
  return Promise.reject(error);
});
