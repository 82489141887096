<template>
  <p-dialog
    :header="title"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <div class="p-text-center" v-if="loading">
      <p-progress-spinner style="width: 50px; height: 50px" />
    </div>
    <div v-else-if="data">
      <Carousel :value="data.imagens" :numVisible="1">
        <template #item="{ data }">
          <img
            style="max-width: 100%; max-height: 500px"
            :src="getUrlImagem(data.url)"
            :alt="data.nome"
          />
        </template>
      </Carousel>
      <div class="info">
        <label>Código:</label>
        <span>{{ data.codigo }}</span>
      </div>
      <div class="info">
        <label>Nome:</label>
        <span>{{ data.nome }}</span>
      </div>
      <div class="info">
        <label>Categoria:</label>
        <span>{{ data.categoria.nome }}</span>
      </div>
      <div class="info">
        <label>Valor:</label>
        <span style="font-size: 0.8rem">
          De
          <span style="text-decoration: line-through">{{
            $formatCurrency(data.valor || 0)
          }}</span>
          por
        </span>
        {{ $formatCurrency(valorAfiliado || 0) }}
        <span style="font-size: 0.8rem" v-if="!$store.getters.isCompraPa">
          ({{ $formatNumber(pontos) }} pts)
        </span>
      </div>
      <div v-if="data.descricao" class="item-card-descricao">
        <span v-html="data.descricao"></span>
      </div>
    </div>

    <div class="p-d-flex p-flex-row-reverse p-jc-start">
      <p-button
        label="Fechar"
        class="p-button-secondary p-mr-2"
        @click="close"
      />
    </div>
  </p-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, ref, watch, computed } from "vue";
import {
  getInstance,
  Item,
  baseUrlImagens,
  calcValorAfiliado,
  calcPontos,
} from "@/services/ItemService";
import Carousel from "primevue/carousel";

export function useShowItem() {
  const data = reactive({
    id: null,
    title: "",
    visible: false,
    show(id: number, title = "Detalhes do Produto") {
      // @ts-ignore
      this.id = id;
      this.title = title;
      this.visible = true;
    },
  });

  watch(
    () => data.visible,
    (v) => !v && (data.id = null)
  );

  return data;
}

export default defineComponent({
  name: "x-item-view",
  components: { Carousel },
  emits: ["update:visible"],
  props: {
    id: [Number, String] as PropType<number>,
    title: {
      type: String,
      default: "Detalhes do Produto",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const data = ref<Item>();

    function reset() {
      data.value = undefined;
    }

    function close() {
      emit("update:visible", false);
    }

    async function load(id: number) {
      loading.value = true;
      try {
        const result = await getInstance().getById(id);
        data.value = result;
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );

    const valorAfiliado = computed(() => {
      return data.value ? calcValorAfiliado(data.value) : NaN;
    });
    const pontos = computed(() => {
      return data.value ? calcPontos(data.value, valorAfiliado.value) : 0;
    });

    return {
      valorAfiliado,
      pontos,
      data,
      loading,

      close,

      updateDialogVisible(v: boolean) {
        emit("update:visible", v);
      },

      getUrlImagem(url: string) {
        return `${baseUrlImagens}/${url}`;
      },
    };
  },
});
</script>

<style scoped>
.info {
  margin: 5px;
}
.info label {
  display: inline-block;
  width: 80px;
  text-align: right;
  margin-right: 5px;
}
.info span {
  font-weight: bold;
}

.item-card-descricao {
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 10px;
  margin: 15px 0;
}
.item-card-descricao * {
  all: initial;
  font-family: inherit;
  color: inherit;
}
</style>