import { App, reactive } from "vue";
import locale from "./primeVueLocale";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Galleria from "primevue/galleria";
import Listbox from "primevue/listbox";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import SplitButton from "primevue/splitbutton";
import TabMenu from "primevue/tabmenu";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tooltip from "primevue/tooltip";
import ToggleButton from "primevue/togglebutton";
import Divider from "primevue/divider";
import ProgressSpinner from "primevue/progressspinner";
import ConfirmationService from "primevue/confirmationservice";
import Tag from "primevue/tag";
import PrimeVue from "primevue/config";
import Timeline from "primevue/timeline";

export default function install(app: App) {
  app.config.globalProperties.$appState = reactive({ inputStyle: "outlined" });

  app.use(PrimeVue, {
    ripple: true,
    locale,
  });

  app.use(ToastService);
  app.use(ConfirmationService);

  app.directive("tooltip", Tooltip);
  app.directive("ripple", Ripple);

  app.component("p-divider", Divider);
  app.component("p-button", Button);
  app.component("p-inputtext", InputText);
  app.component("p-dialog", Dialog);
  app.component("p-datatable", DataTable);
  app.component("p-column", Column);
  app.component("p-inputnumber", InputNumber);
  app.component("p-toolbar", Toolbar);
  app.component("p-tag", Tag);
  app.component("p-dropdown", Dropdown);
  app.component("p-card", Card);
  app.component("p-progress-spinner", ProgressSpinner);
  app.component("p-inline-message", InlineMessage);
  app.component("p-checkbox", Checkbox);
  app.component("p-inputswitch", InputSwitch);
  app.component("p-radiobutton", RadioButton);
  app.component("p-textarea", Textarea);
  app.component("p-message", Message);
  app.component("p-timeline", Timeline);
  app.component("p-autocomplete", AutoComplete);
  app.component("p-multiSelect", MultiSelect);
  app.component("p-progress-bar", ProgressBar);

  // TODO: renomear para `p-${nome}`
  app.component("Accordion", Accordion);
  app.component("AccordionTab", AccordionTab);
  app.component("Breadcrumb", Breadcrumb);
  app.component("Chips", Chips);
  app.component("ColorPicker", ColorPicker);
  app.component("Column", Column);
  app.component("ContextMenu", ContextMenu);
  app.component("DataTable", DataTable);
  app.component("DataView", DataView);
  app.component("DataViewLayoutOptions", DataViewLayoutOptions);
  app.component("FileUpload", FileUpload);
  app.component("Inplace", Inplace);
  app.component("InputMask", InputMask);
  app.component("Galleria", Galleria);
  app.component("Listbox", Listbox);
  app.component("Menu", Menu);
  app.component("Menubar", Menubar);
  app.component("OverlayPanel", OverlayPanel);
  app.component("Paginator", Paginator);
  app.component("Panel", Panel);
  app.component("PanelMenu", PanelMenu);
  app.component("Password", Password);
  app.component("Rating", Rating);
  app.component("SelectButton", SelectButton);
  app.component("Slider", Slider);
  app.component("Sidebar", Sidebar);
  app.component("SplitButton", SplitButton);
  app.component("TabMenu", TabMenu);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("Toast", Toast);
  app.component("ToggleButton", ToggleButton);
}
